import { useAuth0 } from '@auth0/auth0-react';
import { MessageTypeEnum, ProviderNumberTypeEnum, S3BucketEnum } from '@shared/enums';
import { CheckUniqueCampaignNameResponse, GetSegmentsContactCountRequest, ICampaignDetails, ICampaignVolumeEntity, IHttpResponse, ISignedUrlRequest, ITestNumbersViewRecord, SegmentContactCountSocketData, SocketName } from '@shared/models';
import { getCampaignEndDateForStartDate, getCampaignStartDate, getLocalDate, isGsmMessage, isNil, replaceNonGsmCharacters, uuid } from '@shared/services';
import axios from 'axios';
import { FastField, Formik, FormikHelpers } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { Field, RuleType } from 'react-querybuilder';
import { axiosGet, axiosPost, getQueryParamString } from '../../authAxios';
import { useClientContext } from '../../contexts/ClientContext';
import { SocketContext } from '../../contexts/SocketContext';
import { useCustomDataPoints } from '../../hooks/useCustomDataPoints';
import useTestNumbers from '../../hooks/useTestNumbers';
import { getCustomDataPointFields } from '../../providers/custom-data-point.provider';
import { formatBackendMongoDBQuery } from '../../providers/mongodb.provider';
import { SocketSubscription } from '../../providers/socket.provider';
import { isFileLocalSize, isFileMediaType, isFileTollFreeSize } from '../../providers/validation.provider';
import { Button } from '../shared/Buttons/Button';
import Checkbox from '../shared/Form/Checkbox';
import DateTimePicker from '../shared/Form/DateTimePicker';
import { IDropdownValue } from '../shared/Form/Dropdown';
import AudienceDropdown from '../shared/Form/Dropdowns/AudienceDropdown';
import DomainsDropdown from '../shared/Form/Dropdowns/DomainsDropdown';
import SuppressionDropdown from '../shared/Form/Dropdowns/SuppressionDropdown';
import { FileUpload } from '../shared/Form/FileUpload';
import QueryBuilder from '../shared/Form/QueryBuilder/QueryBuilder';
import { IRadioButtonOption, RadioButtonGroup } from '../shared/Form/RadioButton';
import TextAreaTags from '../shared/Form/TextAreaTags';
import { TextInput } from '../shared/Form/TextInput';
import LoadingIndicator from '../shared/LoadingIndicator';
import { ICampaignForm, clickTrackingOptions, getCampaignFormSchema, messageTypeOptions } from './types';

export interface ICampaignDetailsItem extends Omit<ICampaignDetails, 'id'> {
  id?: string;
}

interface ICampaignsFormProps {
  item: ICampaignDetailsItem | undefined;
  onSubmit?: (formValues: ICampaignForm) => void;
  disabled?: boolean;
  setLoading: (val: boolean) => void;
}

export const uploadMediaFile = async (
  file: File,
  numberType: ProviderNumberTypeEnum,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
) => {
  const validateFileSize = numberType === ProviderNumberTypeEnum.LOCAL ? isFileLocalSize : isFileTollFreeSize;

  if (!file || !isFileMediaType(file) || !validateFileSize(file)) {
    setFieldValue('mediaUrl', '');
    setFieldValue('mediaName', '');
    return;
  }

  const mediaFileKey: string = uuid();

  const s3SignedUrlRequest: ISignedUrlRequest = {
    bucket: S3BucketEnum.MMS,
    key: mediaFileKey,
    action: 'putObject',
    contentType: file.type,
  };

  const s3MediaUrlRequest: ISignedUrlRequest = {
    bucket: S3BucketEnum.MMS,
    key: mediaFileKey,
    action: 'getObject',
    contentType: file.type,
    expiresIn: 31536000, // 1 Year
  };

  try {
    const {
      data: uploadUrl,
    } = await axiosGet<IHttpResponse<string>>(`/signed-s3-url?${getQueryParamString(s3SignedUrlRequest)}`);

    await axios.put(uploadUrl, file, {
      headers: {
        'Content-Type': file.type,
        'Content-Disposition': 'attachment',
      },
    });

    const {
      data: mediaUrl,
    } = await axiosGet<IHttpResponse<string>>(`/signed-s3-url?${getQueryParamString(s3MediaUrlRequest)}`);
    setFieldValue('mediaUrl', mediaUrl);
    setFieldValue('mediaName', file.name);
  } catch (error) {
    console.error('Error when attempting to upload media file');
  }
};

const CampaignsForm = ({ item, onSubmit, disabled, setLoading }: ICampaignsFormProps): JSX.Element => {
  const [inlineSuppressionFields, setInlineSuppressionFields] = useState<Field[]>([]);
  const { selectedClientId } = useClientContext();
  const [{ data: customDataPointsResponse }, getCustomDataPoints] = useCustomDataPoints({ skip: 0, take: 1000, isActive: true, personalized: true });
  const [{ data: testNumbersResponse, loading, error }] = useTestNumbers();
  const [contactCount, setContactCount] = useState(NaN as number);
  const [contactCountLoading, setContactCountLoading] = useState(false);
  const [campaignVolumeId, setCampaignVolumeId] = useState('');
  const socketManager = useContext(SocketContext);
  const { user: auth0User } = useAuth0();

  const customDataPoints = customDataPointsResponse?.records ?? [];
  const testNumbers: ITestNumbersViewRecord[] = testNumbersResponse?.records ?? [];

  const initialFormState: ICampaignForm = {
    name: item?.name ?? '',
    messageType: item?.messageType
      ? messageTypeOptions.find((option) => option.value === item?.messageType)
      : messageTypeOptions[0],
    message: item?.message || '',
    startsAt: item?.startsAt ?? getCampaignStartDate(),
    endsAt: item?.endsAt ?? getCampaignEndDateForStartDate(getLocalDate(), getCampaignStartDate()),
    externalId: item?.externalId ?? '',
    mediaUrl: item?.mediaUrl ?? '',
    mediaName: item?.mediaName ?? '',
    mediaFile: item?.mediaUrl ? new File([], 'Media File', { type: 'image/*' }) : undefined,
    sendTestMessages: item?.sendTestMessages ?? false,
    clickTrackingEnabled:
      item?.clickTrackingEnabled !== undefined
        ? clickTrackingOptions.find((option) => option.value === item?.clickTrackingEnabled)
        : undefined,
    url: item?.url ?? '',
    domain: item?.domain ?? '',
    audiences:
      (item?.audiences?.map((a) => {
        return {
          label: a.name,
          value: a.id,
        };
      }) as IDropdownValue[]) ?? [],
    suppressions:
      (item?.suppressions?.map((s) => {
        return {
          label: s.name,
          value: s.id,
        };
      }) as IDropdownValue[]) ?? [],
    frontendInlineSuppression: !!item?.id
      ? { ...(item?.frontendInlineSuppression ?? { rules: [] }), disabled: true }
      : { rules: [], disabled: false },
    backendInlineSuppression: !!item?.id
      ? { ...(item?.frontendInlineSuppression ?? { rules: [] }), disabled: true }
      : { rules: [], disabled: false },
  };

  useEffect(() => {
    const getFields = async () => {
      const fields = await getCustomDataPointFields({
        rules: initialFormState?.frontendInlineSuppression?.rules as RuleType[],
        isActive: true,
        personalized: true
      });
      setInlineSuppressionFields(fields);
    };
    getFields();
  }, []);

  const updateContactCount: SocketSubscription<SegmentContactCountSocketData> = payload => {
    if (payload?.data?.user_id === auth0User?.user_id) {
      setContactCount(payload?.data?.count ?? NaN);
      setContactCountLoading(false);
      setCampaignVolumeId('');
    }
  };

  useEffect(() => {
    const unsubscribeContactCount = socketManager!.subscribe(SocketName.SEGMENT_CONTACT_COUNT, updateContactCount);

    return () => {
      unsubscribeContactCount();
    };
  }, []);



  const isReadOnly: boolean = disabled || (!!item?.id);

  const hasInlineSuppression = (values: ICampaignForm): boolean => {
    return (values?.frontendInlineSuppression?.rules?.length ?? 0) > 0;
  };

  const calculateContactCount = async (values: ICampaignForm) => {
    try {
      setContactCountLoading(true);

      const response = await axiosPost<IHttpResponse<ICampaignVolumeEntity>>('/campaign-volumes');
      const campaignVolume = response?.data;

      const request: GetSegmentsContactCountRequest = {
        clientId: selectedClientId!,
        audienceIds: values?.audiences?.map(audience => audience?.value) ?? [],
        suppressionIds: values?.suppressions?.map(suppression => suppression?.value) ?? [],
        campaignVolumeId: campaignVolume.id,
        inlineSuppressionFrontendFilter: hasInlineSuppression(values) ? values?.frontendInlineSuppression : null,
        inlineSuppressionBackendFilter: hasInlineSuppression(values)
          ? formatBackendMongoDBQuery(values?.frontendInlineSuppression, customDataPoints)
          : null
      };

      socketManager?.sendMessage<GetSegmentsContactCountRequest>(SocketName.SEGMENT_CONTACT_COUNT, request);

      setContactCount(NaN);
      setCampaignVolumeId(campaignVolume.id);
    } catch (error) {
      console.log(error);
      setContactCount(NaN);
      setContactCountLoading(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        if (campaignVolumeId?.length > 0) {
          const response = await axiosGet<IHttpResponse<ICampaignVolumeEntity>>(`/campaign-volumes/${campaignVolumeId}`);
          const campaignVolume = response?.data;

          if (campaignVolume && !campaignVolume.isCalculating) {
            setContactCount(campaignVolume?.count ?? NaN);
            setContactCountLoading(false);
            setCampaignVolumeId('');
          }
        }
      } catch (error) {
        console.error(error);
      }
    }, 5_000);

    return () => clearInterval(interval);
  }, [campaignVolumeId]);

  return (
    <>
      <Formik
        initialValues={initialFormState}
        validationSchema={getCampaignFormSchema(customDataPoints)}
        enableReinitialize
        onSubmit={async (values, formik: FormikHelpers<ICampaignForm>) => {
          try {
            setLoading(true);
            const checkNameResp = await axiosPost<IHttpResponse<CheckUniqueCampaignNameResponse>>('/campaigns/check-unique-name', { name: values.name });
            if (!checkNameResp?.data?.isValid) {
              formik.setFieldError('name', `A campaign with the name "${values.name}" already exists.`);
            } else {
              onSubmit && await onSubmit(values);
            }
          } finally {
            setLoading(false);
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, setFieldError }) => (
          // Set form id so external submit button can still work
          <>
            <form id="campaign-form" onSubmit={handleSubmit}>
              <div className="flex flex-col px-1 pb-16 space-y-8 overflow-hidden divide-y dark:divide-slate-800">
                <div className="space-y-4">
                  <FastField
                    component={TextInput}
                    id="name"
                    name="name"
                    label="Campaign Name"
                    disabled={isReadOnly}
                    value={values.name}
                    error={touched.name ? errors.name : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FastField
                    component={TextInput}
                    id="externalId"
                    name="externalId"
                    label="External ID"
                    disabled={isReadOnly}
                    value={values.externalId}
                    error={touched.externalId ? errors.externalId : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="pt-8">
                  <div className="space-y-4">
                    <AudienceDropdown
                      value={values.audiences}
                      onChange={(newValue) => {
                        setFieldValue('audiences', newValue);
                        setFieldTouched('audiences');
                      }}
                      onBlur={() => {
                        setFieldTouched('audiences');
                      }}
                      disabled={isReadOnly}
                      showError={!!touched.audiences}
                      errorMessage={errors.audiences?.toString()}
                      multiple
                    />
                    <SuppressionDropdown
                      value={values.suppressions}
                      onChange={(newValue) => {
                        setFieldValue('suppressions', newValue);
                        setFieldTouched('suppressions');
                      }}
                      onBlur={() => {
                        setFieldTouched('suppressions');
                      }}
                      disabled={isReadOnly}
                      showError={!!touched.suppressions}
                      errorMessage={errors.suppressions?.toString()}
                    />
                    <div>
                      <span className="ml-1 text-sm font-medium text-gray-700">Inline Suppressions</span>
                      <QueryBuilder
                        fields={inlineSuppressionFields}
                        query={values.frontendInlineSuppression}
                        setQuery={(newValue) => {
                          setFieldValue('frontendInlineSuppression', newValue);
                          setFieldTouched('frontendInlineSuppression');
                        }}
                        maxRules={5}
                        hideAddQuery
                      />
                    </div>
                    <div className="flex flex-row justify-between px-1 mt-1 space-y-8 overflow-hidden divide-y dark:divide-slate-800">
                      <div className="flex">
                        <Button
                          onClick={() => calculateContactCount(values)}
                          type="button"
                          text="Calculate Campaign Volume"
                          disabled={!(values?.audiences?.length >= 1) || contactCountLoading || !!item?.id}
                        />
                        {contactCountLoading && (
                          <span className="inline-flex items-center ml-4">
                            <LoadingIndicator />
                          </span>
                        )}
                        {!contactCountLoading && !isNil(contactCount) && (
                          <span className="inline-flex items-center ml-4">{contactCount.toLocaleString()} contacts</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-8">
                  <div className="space-y-4">
                    <FastField
                      name="messageType"
                      component={RadioButtonGroup}
                      options={messageTypeOptions}
                      label="Message Type"
                      error={touched.messageType && (errors.messageType as any)?.value}
                      disabled={isReadOnly}
                      value={values.messageType}
                      onBlur={handleBlur}
                      onChange={(messageType: IRadioButtonOption) => {
                        setFieldTouched('messageType');
                        setFieldValue('messageType', messageType);
                        // Clear the media url. If it's submitted with the campaign, it will be treated as MMS regardless
                        setFieldValue('mediaUrl', '');
                        setFieldValue('mediaName', '');
                        setFieldValue('mediaFile', null);
                        setFieldTouched('mediaFile', false);
                      }}
                    />
                    {/* TODO: Should we limit users and not allow them to pick dates outside of our send window (Midnight EST-8am EST) */}
                    <div className="sm:flex sm:justify-between">
                      <FastField
                        component={DateTimePicker}
                        label="Starts At"
                        name="startsAt"
                        value={values.startsAt}
                        disabled={isReadOnly}
                        error={touched.startsAt && errors.startsAt ? (errors.startsAt as string) : ''}
                        minDate={new Date()}
                        minHour={6}
                        maxHour={23}
                        onChange={(newValue: Date) => {
                          setFieldTouched('startsAt');
                          setFieldValue('startsAt', newValue);
                          setFieldValue('endsAt', getCampaignEndDateForStartDate(getLocalDate(), newValue));
                        }}
                      />

                      {/* Note: Currently a UI bug where min date doesn't get updated since it's a fast field. Without being a fast field the page gets laggy because the text area can't be a fast field  */}
                      <FastField
                        component={DateTimePicker}
                        id="datePicker_right"
                        label="Ends At"
                        name="endsAt"
                        value={values.endsAt}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="pt-8 space-y-4">
                  <FastField
                    name="clickTrackingEnabled"
                    component={RadioButtonGroup}
                    options={clickTrackingOptions}
                    label="Click Tracking"
                    error={touched.clickTrackingEnabled && (errors.clickTrackingEnabled as any)?.value}
                    disabled={isReadOnly}
                    value={values.clickTrackingEnabled}
                    onBlur={handleBlur}
                    onChange={(clickTrackingEnabled: IRadioButtonOption) => {
                      setFieldTouched('clickTrackingEnabled');
                      setFieldValue('clickTrackingEnabled', clickTrackingEnabled);
                    }}
                  />

                  {values.clickTrackingEnabled?.value && (
                    <FastField
                      component={TextInput}
                      id="url"
                      name="url"
                      label="Redirect URL"
                      disabled={isReadOnly}
                      value={values.url}
                      error={touched.url ? errors.url : ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  )}

                  {values.clickTrackingEnabled?.value && (
                    <DomainsDropdown
                      value={values.domain ?? ''}
                      onChange={(selectedValue) => {
                        setFieldValue('domain', selectedValue?.value);
                      }}
                      onBlur={() => {
                        setFieldTouched('domain');
                      }}
                      disabled={isReadOnly}
                      showError={touched.domain}
                      errorMessage={errors.domain}
                    />
                  )}
                </div>
                <div>
                  <div className="pt-4">
                    <TextAreaTags
                      value={values.message}
                      errorMessageClassName={'mr-2'}
                      onChange={(value: any) => {
                        if (value?.length > 0) {
                          value = replaceNonGsmCharacters(value);
                        }

                        setFieldValue('message', value);
                        if (!!value) {
                          setFieldTouched('message');
                        }
                      }}
                      onBlur={() => {
                        setFieldTouched('message');
                      }}
                      readOnly={isReadOnly}
                      messageType={values.messageType}
                      error={
                        touched.message
                          ? errors.message
                            ? errors.message
                            : !isGsmMessage(values?.message)
                              ? 'Message contains non-GSM characters'
                              : ''
                          : ''
                      }
                      domain={values.domain}
                    />
                  </div>

                  {values.messageType?.value === MessageTypeEnum.MMS && (
                    <>
                      {!isReadOnly && (
                        <FileUpload
                          id="mediaFile"
                          name="mediaFile"
                          label="Media File"
                          fileName={values.mediaName}
                          accepts="image/*, video/*, .pdf"
                          disabled={isReadOnly}
                          value={values.mediaFile}
                          error={touched.mediaFile && (errors.mediaFile as any)}
                          onBlur={handleBlur}
                          placeholder="Select a media file"
                          onChange={(fileUploadEvent: any) => {
                            const file = fileUploadEvent.target.files[0];
                            setFieldTouched('mediaFile');
                            setFieldValue('mediaFile', file);
                            setFieldValue('isMMSVideo', !!file?.type?.match('video.*') ?? false);
                            uploadMediaFile(file, ProviderNumberTypeEnum.LOCAL, setFieldValue);
                          }}
                        />
                      )}

                      {isReadOnly && (
                        <div>
                          <div className="flex justify-between">
                            <label className="block text-sm font-medium text-gray-700">Media File</label>
                          </div>
                          <div className="block w-full bg-gray-100 border-gray-300 rounded-md shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm">
                            <div className="px-3 py-2 mt-1">
                              <a
                                href={values.mediaUrl!}
                                target="_blank"
                                download
                                className="text-sky-500 hover:underline"
                              >
                                {values?.mediaName ?? 'Download File'}
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div className="pt-8 space-y-4">
                  <div className="py-4">
                    <Checkbox
                      id="sendTestMessages"
                      name="sendTestMessages"
                      label="Run Health Check"
                      description="Send messages to all Test Numbers to check campaign deliverability."
                      disabled={isReadOnly || testNumbers?.length === 0}
                      title={!isReadOnly && testNumbers?.length === 0 ? 'Requires Test Numbers to be added' : ''}
                      checked={values.sendTestMessages}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </Formik>
    </>
  );
};

export default CampaignsForm;
