import { Combobox } from '@headlessui/react';
import { LoadingIndicator } from '@Wonder-Cave/ui';
import { Dropdown as DropdownIcon } from '../../Icons/Dropdown';
import { Selector as SelectorIcon } from '../../Icons/Selector';
import { IDropdownValue } from '../Dropdown';

export interface IAutocompleteInputProps {
  search?: string;
  headIcon?: JSX.Element;
  disaabled?: boolean;
  multiple?: boolean;
  query?: string;
  placeholder?: string;
  refreshOptions?: (searchvalue: string) => Promise<void>;
  label?: string;
  onChange?: (value: IDropdownValue | undefined) => void;
  setQuery: (query: string) => void;
  showOptions: boolean;
  setShowOptions: (showOptions: boolean) => void;
  loading?: boolean;
}

export const AutocompleteInput = ({ search, headIcon, disabled, multiple, query, placeholder, refreshOptions, label, onChange, setQuery, showOptions, setShowOptions, loading }) => {
  return <div
    className={`${!search && 'cursor-pointer'
      } flex relative flex-wrap gap-2 w-full py-4 pl-3 pr-10 text-left ${disabled ? 'bg-gray-100' : 'bg-black'
      } border border-black ${showOptions ? 'rounded-t-md' : 'rounded-md'} shadow-sm focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm`}
  ><Combobox.Input
      className={`${!search && 'cursor-pointer'
        } flex-grow border-none p-0 focus:ring-0 text-sm max-w-full text-white ${disabled ? 'bg-gray-100' : 'bg-black'
        } ${!!headIcon && 'pt-px'}`}
      displayValue={!multiple ? (option: IDropdownValue) => option?.label : () => query}
      onChange={(event) => {
        setQuery(event.target.value);
      }}
      placeholder={placeholder ?? (!!refreshOptions ? `Search for a ${label}` : 'Select an option')}
      onBlur={(event: any) => {
        if (event.target.value === '' && !multiple) {
          onChange(undefined);
        }
        // Did we click the button or an option to blur this? If so, don't call blur or the button will open it again
        if (event.relatedTarget?.id?.includes('headlessui-combobox-button')) {
          return;
        }
        if (event.relatedTarget?.id?.includes('headlessui-combobox-option')) {
          return;
        }
        setShowOptions(false);
      }}
      onFocus={(e: any) => {
        if (!refreshOptions && !showOptions) {
          setShowOptions(true);
        }
      }}
      onClick={(e: any) => {
        if (!refreshOptions && !showOptions) {
          setShowOptions(true);
        }
      }}
      autoComplete={'off'} // https://bit.ly/3Jw8xGn
      readOnly={loading || !search}
      disabled={!search}
    />
    {loading ? (
      <LoadingIndicator dropdown />
    ) : (
      !refreshOptions && (
        <Combobox.Button
          onClick={(e: any) => {
            e.preventDefault(); // Headless clicks the button a bunch. Stop them!
            setShowOptions((prevState) => {
              return !prevState;
            });
          }}
          className="absolute inset-y-0 right-0 flex items-center pr-4"
          onBlur={() => {
            setShowOptions(false);
          }}
        >
          <div className='text-wc-blue'>{showOptions ? <SelectorIcon /> : <DropdownIcon width={12} height={12} />}</div>
        </Combobox.Button>
      )
    )};
  </div>;
};