import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useJourneys from '../../hooks/useJourneys';
import { NewButton } from '../shared/Buttons/NewButton';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import NewTextInput from '../shared/Form/NewTextInput';
import TableList from '../shared/Table/TableList';
import { getColumns } from './types';

const Journeys = () => {
  const PAGE_LIMIT = 10;
  const navigate = useNavigate();
  const columns = getColumns(
    {
      title: (journey) => {
        navigate(`/journeys/${journey.id}`);
      },
    });
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [{ data: journeyData, loading: journeyLoading, error: journeyError }, getJourneys] = useJourneys({
    take: PAGE_LIMIT,
    skip: page,
    name: search
  });
  const deleteRow = () => { };

  useEffect(() => {
    getJourneys();
  }, [page]);
  return <>
    <div className='flex'>
      <h1>Journeys</h1>
      <NewTextInput onEnter={() => {
        if (page != 0) {
          setPage(0);
        } else {
          getJourneys();
        }
      }} className='ml-auto mr-4 w-72' name="search" search placeholder='Search' onChange={(e) => setSearch(e.target.value)} value={search} />
      <NewButton onClick={() => navigate('/journeys/add')} variant={ButtonVariantEnum.PRIMARY}>CREATE NEW</NewButton>
    </div>
    <TableList
      columns={columns}
      items={journeyData?.records ?? []}
      totalCount={journeyData?.totalCount ?? 0}
      limit={PAGE_LIMIT}
      loading={journeyLoading}
      shimmer
      paginate
      onPaginate={(page) => setPage(page * PAGE_LIMIT)}
      actions={[
        {
          label: 'Delete',
          action: (row) => deleteRow(),
          hidden: false
        }
      ]}
    />
  </>;
};

export default Journeys;