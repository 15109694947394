import { AudienceContactCountSocketData, GetAudienceContactCountRequest, IAudienceEntity, ICreateAudienceRequest, IHttpResponse, IUpdateAudienceRequest, SocketName } from '@shared/models';
import { isFinite } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { axiosDelete, axiosGet, axiosPost, axiosPut } from '../../authAxios';
import { useClientContext } from '../../contexts/ClientContext';
import { SocketContext } from '../../contexts/SocketContext';
import { useAudiences } from '../../hooks/useAudiences';
import { useCustomDataPoints } from '../../hooks/useCustomDataPoints';
import { formatBackendMongoDBQuery } from '../../providers/mongodb.provider';
import { SocketSubscription } from '../../providers/socket.provider';
import { Button } from '../shared/Buttons/Button';
import LoadingIndicator from '../shared/LoadingIndicator';
import { Table } from '../shared/Table/Table';
import { AudienceDetailPanel } from './AudienceDetailsPanel';
import { IAudienceForm, defaultAudiencesTableOptions, getColumns } from './types';

export const Audiences = () => {
  const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IAudienceEntity>();
  const { selectedClientId } = useClientContext();
  const [loadingIds, setLoadingIds] = useState<string[]>([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [tableOptions, setTableOptions] = useState(defaultAudiencesTableOptions);
  const [{ data: audiences, loading }, getAudiences] = useAudiences(tableOptions);
  const [{ data: customDataPoints }, getCustomDataPoints] = useCustomDataPoints({ skip: 0, take: 10000, isActive: true });

  const socketManager = useContext(SocketContext);

  const closeDetailsPanel = () => {
    setShowDetailsPanel(false);
    setSelectedItem(undefined);
  };

  const handleSubmit = async (formData: IAudienceForm) => {
    setLoadingSubmit(true);
    const customDataPointRecords = customDataPoints?.records ?? [];
    try {
      let response;
      const backendFilter = formatBackendMongoDBQuery(formData.frontendFilter, customDataPointRecords);

      if (!!selectedItem && selectedItem.id) {
        const createRequest: ICreateAudienceRequest = {
          clientId: selectedClientId ?? '',
          name: formData.name,
          frontendFilter: formData.frontendFilter,
          backendFilter
        };

        response = await axiosPut(`/audiences/${selectedItem.id}`, createRequest);
      } else {
        const updateRequest: IUpdateAudienceRequest = {
          clientId: selectedClientId ?? '',
          name: formData.name,
          frontendFilter: formData.frontendFilter,
          backendFilter
        };

        response = await axiosPost('/audiences', updateRequest);
      }
      if (response?.status === 200) {
        closeDetailsPanel();
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleRefetch();
      setLoadingSubmit(false);
    }
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    try {
      const response = await axiosDelete(`/audiences/${selectedItem?.id}`);
      if (response?.status === 200 && response?.data) {
        closeDetailsPanel();
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleRefetch();
      setDeleteLoading(false);
    }
  };

  const calculateContactCount = async (row: IAudienceEntity) => {
    try {
      const record = getRecordById(row.id);

      if (record) {
        record.isCalculating = true;
        addLoadingId(record.id);
      }

      const request: GetAudienceContactCountRequest = {
        clientId: selectedClientId!,
        audienceId: row?.id ?? ''
      };

      socketManager?.sendMessage<GetAudienceContactCountRequest>(SocketName.AUDIENCE_CONTACT_COUNT, request);
    } catch (error) {
      console.log(error);
    }
  };

  const updateContactCount: SocketSubscription<AudienceContactCountSocketData> = async payload => {
    const data = payload?.data?.audience;
    const record = getRecordById(data?.id);

    if (record) {
      record.count = isFinite(data?.count) ? data?.count! : NaN;
      record.isCalculating = false;
      removeLoadingId(record.id);
    }
  };

  const getRecordById = (id?: string) => {
    return id
      ? audiences?.records?.find(record => record.id === id)
      : undefined;
  };

  const columns = getColumns(
    {
      count: (item: IAudienceEntity) => loadingIds?.includes(item?.id) ? <LoadingIndicator /> : (item.count ? item.count : '-'),
      frontendFilter: (item: IAudienceEntity) => JSON.stringify(item.frontendFilter),
    },
    {
      name: (item: IAudienceEntity) => {
        setSelectedItem(item);
        setShowDetailsPanel(true);
      },
    },
    [{
      label: 'Calculate Volume',
      action: async (row: IAudienceEntity) => {
        await calculateContactCount(row);
      },
      hidden: false
    }]
  );

  useEffect(() => {
    const unsubscribeContactCount = socketManager!.subscribe(SocketName.AUDIENCE_CONTACT_COUNT, updateContactCount);

    return () => {
      unsubscribeContactCount();
    };
  }, []);

  const handleRefetch = async () => {
    try {
      setTableOptions({ ...(tableOptions ?? {}), clientId: selectedClientId });
      await getAudiences();
    } catch (error) { }
  };

  useEffect(() => {
    addAllLoadingIds();

    const interval = setInterval(async () => {
      const calculatingAudiences = audiences?.records?.filter(record => record.isCalculating) ?? [];
      addAllLoadingIds();

      for (const audience of calculatingAudiences) {
        refreshAudience(audience);
      }
    }, 15_000);

    return () => clearInterval(interval);
  }, [audiences]);

  const refreshAudience = async (audience: IAudienceEntity) => {
    try {
      const record = audiences?.records?.find(record => record.id === audience.id);

      if (record) {
        const response = await axiosGet<IHttpResponse<IAudienceEntity>>(`/audiences/${record.id}`);
        const audience = response?.data;

        if (audience) {
          Object.assign(record, audience);
        }

        if (!audience?.isCalculating) {
          removeLoadingId(audience.id);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addLoadingId = (id: string) => {
    setLoadingIds(ids => [...ids, id]);
  };

  const removeLoadingId = (id: string) => {
    setLoadingIds(ids => ids.filter(x => x !== id));
  };

  const addAllLoadingIds = () => {
    setLoadingIds(audiences?.records?.filter(s => s.isCalculating)?.map(s => s?.id)?.filter(v => !!v) ?? []);
  };

  return (
    <>
      <h2 className="header-page">
        Audiences
        <Button onClick={() => setShowDetailsPanel(true)}>Add Audience</Button>
      </h2>
      <Table
        items={audiences?.records}
        columns={columns}
        loading={loading}
        count={audiences?.totalCount}
        tableSearchOptions={tableOptions}
        onSearchOptionChange={(request) => setTableOptions(request)}
        paginate
        onRefresh={handleRefetch}
      ></Table>
      <AudienceDetailPanel
        show={showDetailsPanel}
        onClosePanel={closeDetailsPanel}
        selectedItem={selectedItem}
        handleSubmit={handleSubmit}
        handleDelete={handleDelete}
        deleteLoading={deleteLoading}
        loading={loadingSubmit}
      />
    </>
  );
};

