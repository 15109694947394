import { Icon } from './Icon';

export const Support = () => (
  <Icon nav={false}>
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
      <g clipPath="url(#clip0_640_666)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9007 15.4958H11.5872V14.6459C11.5872 13.7702 12.1538 13.0104 13.0037 12.7528C14.1112 12.418 14.8839 11.3878 14.8839 10.2288C14.8839 8.79941 13.6734 7.58891 12.244 7.58891C10.8145 7.58891 9.60405 8.77365 9.60405 10.2288V10.8856H8.29053V10.2288C8.29053 8.0525 10.0676 6.27539 12.244 6.27539C14.4203 6.27539 16.1974 8.09114 16.1974 10.2288C16.1974 11.9544 15.0384 13.5126 13.3772 14.0149C13.0939 14.105 12.9007 14.3497 12.9007 14.6459V15.4958ZM12.244 18.4576C11.7031 18.4576 11.2524 18.0198 11.2524 17.4661C11.2524 16.9123 11.6902 16.4745 12.244 16.4745C12.7977 16.4745 13.2355 16.9123 13.2355 17.4661C13.2355 18.0198 12.7977 18.4576 12.244 18.4576Z"
          fill="currentColor"
        />
        <path
          d="M12.2439 24.5616C5.52182 24.5616 0.0488281 19.0886 0.0488281 12.3665C0.0488281 5.64438 5.52182 0.171387 12.2439 0.171387C18.9661 0.171387 24.4391 5.64438 24.4391 12.3665C24.4391 19.0886 18.9661 24.5616 12.2439 24.5616ZM12.2439 1.45915C6.23009 1.45915 1.33659 6.35265 1.33659 12.3665C1.33659 18.3804 6.23009 23.2739 12.2439 23.2739C18.2578 23.2739 23.1513 18.3804 23.1513 12.3665C23.1513 6.35265 18.2578 1.45915 12.2439 1.45915Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_640_666">
          <rect width="24.3902" height="24.3902" fill="white" transform="translate(0.0488281 0.171387)" />
        </clipPath>
      </defs>
    </svg>
  </Icon>
);
