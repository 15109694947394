import { Icon } from './Icon';

export const Contacts = () => (
  <Icon nav>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 41 40" fill="none">
      <g clipPath="url(#clip0_27_523)">
        <path
          d="M32.4245 22.904C31.0102 21.4897 28.717 21.4897 27.3027 22.904L24.2543 25.9524C23.8973 26.3232 23.3205 26.3919 22.8811 26.1172C21.1647 24.9363 19.5855 23.5769 18.1437 22.0664C16.6333 20.6383 15.2738 19.0455 14.0792 17.329C13.8046 16.8896 13.8732 16.3129 14.244 15.9559L17.2924 12.9075C18.7067 11.4931 18.7067 9.19995 17.2924 7.7856L10.5777 1.05715C9.14957 -0.343471 6.87013 -0.343471 5.45578 1.05715L2.16021 4.35272C1.29513 5.21781 0.704669 6.33006 0.498696 7.53844C-0.146687 11.0537 -0.544902 20.35 9.65764 30.5662C17.5533 38.4482 24.8997 39.9998 29.4448 39.9998C30.5296 39.9998 31.6144 39.9037 32.6717 39.7252C33.8801 39.5192 35.0061 38.9288 35.8712 38.0637L39.1667 34.7681C40.5674 33.34 40.5674 31.0606 39.1667 29.6325L32.4383 22.9178L32.4245 22.904ZM8.02358 2.48523C8.32568 2.48523 8.61404 2.60881 8.82001 2.81479L15.5347 9.54324C15.9742 9.98265 15.9742 10.6967 15.5347 11.1361L14.3264 12.3307L6.01878 4.02316L7.22715 2.81479C7.43313 2.60881 7.72149 2.48523 8.02358 2.48523ZM32.2323 37.2398C29.1015 37.8165 20.7939 38.1461 11.429 28.7811C2.06409 19.4162 2.37992 11.1224 2.95664 7.97785C3.08023 7.27754 3.42352 6.61842 3.93158 6.12409L4.24741 5.80826L12.5687 14.1296L12.5001 14.1982C11.3054 15.3929 11.0995 17.2604 12.0057 18.6885C13.2828 20.5559 14.752 22.2861 16.3998 23.8378C17.9652 25.4718 19.6954 26.9411 21.5492 28.2319C22.9772 29.1382 24.8447 28.9322 26.0394 27.7375L26.108 27.6689L34.4294 35.9902L34.1135 36.306C33.6055 36.8141 32.9463 37.1574 32.2323 37.281V37.2398ZM37.3954 32.9693L36.187 34.1776L27.8657 25.8701L29.0603 24.6617C29.4997 24.2223 30.2138 24.2223 30.6532 24.6617L37.3679 31.3627C37.8073 31.8021 37.8073 32.5161 37.3679 32.9693H37.3954Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_27_523">
          <rect width="40" height="40" fill="white" transform="translate(0.210571)" />
        </clipPath>
      </defs>
    </svg>
  </Icon>
);