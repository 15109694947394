import { Icon } from './Icon';

export const Logout = () => (
  <Icon nav={false}>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" viewBox="0 0 20 25" fill="none">
      <g clipPath="url(#clip0_640_669)">
        <path
          d="M10.2373 16.1513C10.2373 16.1513 9.64602 16.4027 9.64602 16.738V21.5993L1.53705 23.6108V1.90269L9.64602 3.91426V8.77554C9.64602 8.77554 9.89942 9.36225 10.2373 9.36225C10.5752 9.36225 10.8286 9.1108 10.8286 8.77554V3.41136C10.8286 3.41136 10.6596 2.90847 10.4062 2.82466L1.19918 0.561646C1.19918 0.561646 0.776838 0.561646 0.69237 0.645461C0.607902 0.729276 0.438965 0.896907 0.438965 1.14835V24.3652C0.438965 24.3652 0.438965 24.7004 0.69237 24.8681C0.776838 24.8681 0.945775 25.0357 1.03024 25.0357C1.03024 25.0357 1.11471 25.0357 1.19918 25.0357L10.4062 22.7727C10.4062 22.7727 10.8286 22.5212 10.8286 22.186V16.8218C10.8286 16.8218 10.5752 16.2351 10.2373 16.2351V16.1513Z"
          fill="currentColor"
        />
        <path
          d="M19.7822 12.3796L14.9675 7.60214C14.9675 7.60214 14.3763 7.35069 14.1229 7.60214C13.8695 7.85359 13.8695 8.18885 14.1229 8.44029L17.9239 12.212H6.18283C6.18283 12.212 5.59155 12.4634 5.59155 12.7987C5.59155 13.1339 5.84496 13.3854 6.18283 13.3854H18.0084L14.1229 17.2409C14.1229 17.2409 13.8695 17.8276 14.1229 18.0791C14.2073 18.1629 14.3763 18.2467 14.5452 18.2467C14.7141 18.2467 14.7986 18.2467 14.9675 18.0791L19.7822 13.3016C19.7822 13.3016 19.9512 12.7149 19.7822 12.4634V12.3796Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_640_669">
          <rect width="19.5122" height="24.3902" fill="white" transform="translate(0.438965 0.561646)" />
        </clipPath>
      </defs>
    </svg>
  </Icon>
);