import { Combobox } from '@headlessui/react';
import { ISearchRequest } from '@shared/models';
import { isEqual } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { ErrorMessage } from '../ErrorMessage';
import { AutocompleteInput } from './AutocompleteInput';
import { AutocompleteLabel } from './AutocompleteLabel';
import { AutocompleteOptions } from './AutocompleteOptions';

interface IDropdownProps {
  error?: string;
  top?: boolean;
  showError?: boolean;
  label?: string;
  disabled?: boolean;
  loading?: boolean;
  onBlur?: Function;
  options?: IDropdownValue[];
  placeholder?: string;
  searchOptions?: ISearchRequest;
  refreshOptions?: (searchvalue: string) => Promise<void>;
  debounceTime?: number;
  full?: boolean;
  labelColor?: string;
  value: IDropdownValue;
  onChange: (value: IDropdownValue) => void;
  multiple?: boolean;
  headIcon?: JSX.Element;
  search?: boolean;
}

export interface IDropdownValue {
  label: string;
  value: any;
  additionalData?: any;
}

const AutocompleteBlack = ({
  options,
  value: selectedOption,
  label,
  disabled,
  loading,
  onChange,
  placeholder,
  error,
  showError,
  onBlur,
  refreshOptions,
  debounceTime,
  multiple,
  search = true,
  full,
  labelColor,
  headIcon,
}: IDropdownProps): JSX.Element => {
  const debounce = useRef<any>();
  const [query, setQuery] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [_options, setOptions] = useState(options ?? []);
  useEffect(() => {
    if (!!refreshOptions) {
      if (query.length > 1) {
        clearTimeout(debounce.current);
        debounce.current = setTimeout(async () => {
          await refreshOptions(query); // Wait for the query, we don't want to show old options
          setShowOptions(true);
        }, debounceTime ?? 300);
      }
    } else {
      setOptions(() =>
        !!options
          ? options.filter((option) =>
            option.label.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))
          )
          : []
      );
    }
  }, [query]);

  useEffect(() => {
    if (!refreshOptions && _options!.length > 0 && !isEqual(options, _options) && query.length <= 0) {
      setOptions(options!);
    }
  }, [options]);

  const _onChange = (selectedValue: IDropdownValue) => {
    if (!selectedValue?.value) {
      return;
    }
    const change = onChange as (selectedValue: IDropdownValue) => void;
    setQuery('');
    change(selectedValue);
    setShowOptions(false);
  };

  const _onBlur = () => {
    if ((!selectedOption || multiple) && !!refreshOptions) {
      setQuery('');
    }
    onBlur && onBlur();
  };

  return (
    <div onBlur={_onBlur} className={`${full ? 'w-full' : ''}`}>
      <>
        <Combobox
          value={selectedOption}
          onChange={_onChange}
          multiple={multiple}
          disabled={disabled}
        >
          {({ open }) => (
            <>
              {!!label && (
                <AutocompleteLabel label={label} labelColor={labelColor} />
              )}
              <div className="relative">
                <AutocompleteInput
                  search={search}
                  headIcon={headIcon}
                  disabled={disabled}
                  showOptions={showOptions}
                  setShowOptions={setShowOptions}
                  multiple={multiple}
                  query={query}
                  placeholder={placeholder}
                  label={label}
                  onChange={onChange}
                  setQuery={setQuery}
                  refreshOptions={refreshOptions}
                  loading={loading}
                />
                {((options?.length ?? 0) > 0 || _options.length > 0) && (
                  <AutocompleteOptions internalOptions={_options} options={options!} selectedOption={selectedOption} showOptions={showOptions} disabled={disabled} refreshOptions={refreshOptions} query={query} />
                )}
              </div>
            </>
          )}
        </Combobox>
        <ErrorMessage show={showError ?? false} message={error} />
      </>
    </div>
  );
};

export default AutocompleteBlack;
