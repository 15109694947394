import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { CampaignStatusEnum } from '@shared/enums';
import { ICampaignDetails } from '@shared/models';
import { useState } from 'react';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import { DetailsPanel } from '../shared/DetailsPanel/DetailsPanel';
import { DetailsPanelSize, IButtonOptions } from '../shared/DetailsPanel/types';
import CampaignDetailsSummary from './CampaignDetailsSummary';
import CampaignsForm from './CampaignForm';
import { ICampaignForm } from './types';

interface ICampaignDetailsPanelProps {
  show: boolean;
  saveLoading?: boolean;
  pauseLoading?: boolean;
  exporting?: boolean;
  deleteLoading?: boolean;
  cloneLoading?: boolean;
  selectedItem?: ICampaignDetails;
  errorMessage?: string;
  hideClone?: boolean;
  onClosePanel: () => void;
  handleSubmit?: (formData: ICampaignForm) => Promise<void>;
  handleActiveToggle?: () => Promise<void>;
  handleDelete?: () => Promise<void>;
  handleClone?: () => Promise<void>;
  handleExportReplies?: () => Promise<void>;
  formErrors?: { [key: string]: string; };
}

const CampaignDetailsPanel = ({
  show,
  saveLoading,
  pauseLoading,
  deleteLoading,
  cloneLoading,
  exporting,
  selectedItem,
  errorMessage,
  hideClone,
  onClosePanel,
  handleSubmit,
  handleActiveToggle,
  handleDelete,
  handleClone,
  handleExportReplies,
  formErrors,
}: ICampaignDetailsPanelProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(saveLoading ?? false);

  const showPauseButton = (): boolean => {
    const isCampaignSelected = !!selectedItem?.id;

    const isProcessedCampaign = [
      CampaignStatusEnum.DELIVERED,
      CampaignStatusEnum.FAILED,
      CampaignStatusEnum.SENT
    ].includes(selectedItem?.status as CampaignStatusEnum);

    return isCampaignSelected && !isProcessedCampaign;
  };

  const leftPanelButtonOptions: IButtonOptions[] = [
    {
      text: selectedItem?.isActive ? 'Pause' : 'Resume',
      visible: showPauseButton(),
      onClick: handleActiveToggle,
      variant: selectedItem?.isActive ? ButtonVariantEnum.DELETE : ButtonVariantEnum.SECONDARY,
      loading: pauseLoading,
    },
    {
      text: 'Delete',
      visible: !!selectedItem?.id && selectedItem.totalMessagesSent <= 0 && selectedItem.totalMessagesFailed <= 0 && selectedItem.totalMessagesDelivered <= 0,
      onClick: handleDelete,
      variant: ButtonVariantEnum.DELETE,
      loading: deleteLoading,
    },
    {
      text: 'Clone',
      visible: !hideClone && !!selectedItem?.id,
      onClick: handleClone,
      variant: ButtonVariantEnum.TERTIARY,
      loading: cloneLoading,
      hideConfirmation: true
    },
  ];

  return (
    <DetailsPanel
      item={selectedItem}
      title={
        !!selectedItem?.id ? (
          <div>
            <div>{'View Campaign'}</div>
            {selectedItem.tripWired && (
              <div className="flex items-center">
                <ExclamationCircleIcon className="w-6 h-6 mr-1 text-red-700" />
                <span className="text-red-700">
                  {'Tripped On: ' + new Date(selectedItem?.tripWiredOn ?? Date.now()).toLocaleString()}
                </span>
              </div>
            )}
          </div>
        ) : (
          'Add Campaign'
        )
      }
      formId="campaign-form"
      size={DetailsPanelSize.Medium}
      show={show}
      sticky={true}
      closePanel={onClosePanel}
      loading={loading}
      primaryButtonOptions={{ text: 'Save', visible: !selectedItem?.id }}
      leftPanelButtonOptions={leftPanelButtonOptions}
      errorMessage={errorMessage}
    >
      <CampaignsForm item={selectedItem} onSubmit={handleSubmit} disabled={cloneLoading} setLoading={setLoading} />
      {!!selectedItem?.id && <CampaignDetailsSummary selectedItem={selectedItem} />}
    </DetailsPanel>
  );
};

export default CampaignDetailsPanel;
