import { addDays, addHours, addMilliseconds, addMinutes, addMonths, addSeconds, addWeeks, addYears, hoursToMilliseconds, minutesToMilliseconds, secondsToMilliseconds, setHours, setMilliseconds, setMinutes, setSeconds, startOfDay, startOfHour, startOfMonth, subDays, subHours, subMilliseconds, subMinutes, subMonths, subSeconds, subWeeks, subYears } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

export interface RelativeDate {
  years?: number;
  months?: number;
  weeks?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
}

export interface RelativeDateOptions {
  startOfDay?: boolean;
  startOfHour?: boolean;
  startOfMonth?: boolean;
  clearTime?: boolean;
  reverseAmounts?: boolean;
}

export enum TimeoutReason {
  TIMEOUT = 'TIMEOUT'
}

export function getCurrentDateTimestamp(options?: RelativeDateOptions) {
  return getCurrentDate(options).toISOString();
}

export function getRelativeDateTimestamp(relativeDate: RelativeDate, options?: RelativeDateOptions) {
  return getRelativeDate(relativeDate, options).toISOString();
}

export function getCurrentDate(options?: RelativeDateOptions) {
  return getRelativeDate({}, options);
}

export function getRelativeDate(relativeDate: RelativeDate, options?: RelativeDateOptions) {
  let { years = 0, months = 0, weeks = 0, days = 0, hours = 0, minutes = 0, seconds = 0, milliseconds = 0 } = relativeDate;

  if (options?.reverseAmounts) {
    years *= -1;
    months *= -1;
    weeks *= -1;
    days *= -1;
    hours *= -1;
    minutes *= -1;
    seconds *= -1;
    milliseconds *= -1;
  }

  let date = new Date();
  date = years > 0 ? addYears(date, years) : subYears(date, Math.abs(years));
  date = months > 0 ? addMonths(date, months) : subMonths(date, Math.abs(months));
  date = weeks > 0 ? addWeeks(date, weeks) : subWeeks(date, Math.abs(weeks));
  date = days > 0 ? addDays(date, days) : subDays(date, Math.abs(days));
  date = hours > 0 ? addHours(date, hours) : subHours(date, Math.abs(hours));
  date = minutes > 0 ? addMinutes(date, minutes) : subMinutes(date, Math.abs(minutes));
  date = seconds > 0 ? addSeconds(date, seconds) : subSeconds(date, Math.abs(seconds));
  date = milliseconds > 0 ? addMilliseconds(date, milliseconds) : subMilliseconds(date, Math.abs(milliseconds));

  if (options?.startOfHour) {
    date = startOfHour(date);
  }

  if (options?.startOfDay) {
    date = startOfDay(date);
  }

  if (options?.startOfMonth) {
    date = startOfMonth(date);
  }

  // Set all time values to 0, ex. YYYY-MM-DDT00:00:00.000Z
  if (options?.clearTime) {
    date = setHours(date, 0);
    date = setMinutes(date, 0);
    date = setSeconds(date, 0);
    date = setMilliseconds(date, 0);
    date = zonedTimeToUtc(date, 'UTC');
  }

  return date;
}

export async function timeoutSeconds<T>(promise: Promise<T>, seconds: number) {
  return await timeoutMs(promise, secondsToMilliseconds(seconds));
}

export async function timeoutMs<T>(promise: Promise<T>, ms: number) {
  return await Promise.race([
    promise,
    new Promise<T>((resolve, reject) => setTimeout(() => reject(TimeoutReason.TIMEOUT), ms))
  ]);
}

export async function waitUntilSeconds(startMs: number, seconds: number, options?: { log?: boolean; }) {
  return await waitUntilMs(startMs, secondsToMilliseconds(seconds), options?.log);
}

export async function waitUntilMs(startMs: number, ms: number, log?: boolean) {
  if (log) {
    console.log(`Waiting Until ${ms} ms has passed`);
  }

  const elapsedMs = Date.now() - startMs;

  if (elapsedMs < ms) {
    await waitMs(ms - elapsedMs);
  }
}

export async function waitHours(hours: number) {
  await waitMs(hoursToMilliseconds(hours));
}

export async function waitMinutes(minutes: number) {
  await waitMs(minutesToMilliseconds(minutes));
}

export async function waitSeconds(seconds: number) {
  await waitMs(secondsToMilliseconds(seconds));
}

export async function waitMs(ms: number) {
  await new Promise(resolve => setTimeout(resolve, ms));
}
