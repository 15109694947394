import { IClientEntity } from '@shared/models';
import { useClientContext } from 'apps/a2p-portal/src/contexts/ClientContext';
import { useEffect } from 'react';
import { IDropdownValue } from '../Dropdown';
import Autocomplete from './Autocomplete';

interface IClientDropdownProps {
  value: IDropdownValue | (IDropdownValue | undefined)[] | undefined;
  onChange: (newValue: IDropdownValue | (IDropdownValue | undefined)[] | undefined) => void;
  onBlur?: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
  gridFilter?: boolean;
  placeholder?: string;
  multiple?: boolean;
  headIcon?: JSX.Element;
  label?: string;
}

const ClientDropdown = ({
  value,
  onChange,
  onBlur,
  showError,
  disabled,
  errorMessage,
  gridFilter,
  placeholder,
  multiple = false,
  headIcon,
  label,
}: IClientDropdownProps): JSX.Element => {
  const { permissionsLoaded, refreshClients, clients, loading } = useClientContext();
  useEffect(() => {
    if (permissionsLoaded) {
      refreshClients();
    }
  }, [permissionsLoaded]);

  const clientsOptions: IDropdownValue[] =
    clients?.records.map((client: IClientEntity) => ({
      label: client.name ?? '',
      value: gridFilter ? client.name ?? '' : client.id ?? '',
    })) ?? [];

  useEffect(() => {
    if (!value) {
      onChange(clientsOptions[0]);
    }
  }, [clients]);

  return (
    <Autocomplete
      label={label}
      multiple={multiple}
      search={false}
      loading={!disabled && loading}
      value={multiple ? value : clientsOptions.find(co => co.value === (value as IDropdownValue)?.value)}
      options={clientsOptions}
      onChange={onChange}
      onBlur={onBlur}
      error={errorMessage}
      disabled={disabled}
      showError={showError}
      placeholder={placeholder}
      headIcon={headIcon}
    />
  );
};

export default ClientDropdown;