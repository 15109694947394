import { Button, ButtonVariantEnum, Chip, GlacierCheckbox, Info, Shimmer, ShimmerType, Static, TableList } from '@Wonder-Cave/ui';
import { ClockIcon } from '@heroicons/react/outline';
import { CampaignStatusEnum, MessageTypeEnum, ProviderName, RecurringCampaignStatusEnum } from '@shared/enums';
import { ICampaignEntity, IHttpResponse, IRecurringCampaign, IRecurringCampaignInstanceOverview, IRecurringCampaignMetricsClickRate, IRecurringCampaignMetricsDeliveredRate, IRecurringCampaignMetricsOptoutRate, ISearchRequest, ITestMessageResponse, UpdateCampaignRequest } from '@shared/models';
import { getOxfordCommaString } from '@shared/services';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosGet, axiosPatch, axiosPost } from '../../authAxios';
import { NotificationType, useNotifications } from '../../contexts/NotificationContext';
import { useCustomDataPoints } from '../../hooks/useCustomDataPoints';
import useRecurringCampaignCampaigns from '../../hooks/useRecurringCampaignCampaigns';
import CampaignDeliveredProgressBar from '../Campaigns/CampaignDeliveredProgressBar';
import CampaignPauseResumeModal from '../Campaigns/CampaignPauseResumeModal';
import MessagePreview from '../shared/Form/MessagePreview';
import MessageStatus from '../shared/Message/MessageStatus';
import RecurringCampaignDeleteModal from './RecurringCampaignDeleteModal';
import RecurringCampaignPauseResumeModal from './RecurringCampaignPauseResumeModal';
import { defaultRecurringCampaignCampaignsTableOptions, getCampaignColumns } from './types';

interface IMetricCardProps {
  title: string;
  metric: number | null | undefined;
  loading: boolean;
  footer?: React.ReactNode;
  _export?: boolean;
  onExport?: () => void;
  exportLoading?: boolean;
  flat?: boolean;
  disabled?: boolean;
}

const MetricCard = ({ title, loading, metric, footer, _export, onExport, flat, exportLoading, disabled = false }: IMetricCardProps) => {
  let metricText = metric !== null && metric !== undefined
    ? `${isFinite(metric!) ? (flat ? metric : metric!.toFixed(1)) : 0}${flat ? '' : '%'}`
    : '-';

  return (
    <div
      className={`${flat ? '' : 'rounded-2.5xl'} bg-white flex flex-col items-center p-4 space-y-4 ${flat ? '' : 'w-44'
        }`}
      style={{ boxShadow: flat ? '' : '0px 0px 70px 0px #E9EBF0' }}
    >
      <h5 className="text-medium-gray">{title}</h5>
      {loading ? (
        <Shimmer type={ShimmerType.CUSTOM} className="w-16 h-4" />
      ) : (
        <div className="text-lg">
          {metricText}
        </div>
      )}
      {!!onExport ? (
        <div
          onClick={_export && !exportLoading && !disabled ? onExport : () => { }}
          className={`${(_export && !exportLoading && !disabled)
            ? 'cursor-pointer text-wc-blue decoration-wc-blue decoration-2 hover:underline text-sm'
            : `cursor-default text-medium-gray ${exportLoading ? 'animate-pulse' : ''}`
            }`}
        >
          <div className='flex items-center'>
            Export Data
            {disabled && <Info className="ml-1" text={`Unable to export ${title} data as it has been more than 30 days since campaign start date.`} />}
          </div>
        </div>
      ) : (
        footer
      )}
    </div>
  );
};

export const RecurringCampaignsDetails = () => {
  const { id } = useParams<any>();
  const [recurringCampaign, setRecurringCampaign] = useState<IRecurringCampaign>();
  const navigate = useNavigate();
  const [showMetrics, setShowMetrics] = useState(false);
  const [clientName, setClientName] = useState('');
  const [providerName, setProviderName] = useState('');
  const [recurringCampaignTestMessage, setRecurringCampaignTestMessage] = useState<ITestMessageResponse[]>();
  const [pauseLoading, setPauseLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [showPauseModal, setShowPauseModal] = useState(false);
  const [isCampaignPauseLoading, setIsCampaignPauseLoading] = useState(false);
  const [isCampaignActive, setIsCampaignActive] = useState(false);
  const [campaignId, setCampaignId] = useState('');
  const { addNotification } = useNotifications();
  const [metricsDeliveredRateLoading, setMetricsDeliveredRateLoading] = useState(true);
  const [metricsClickRateLoading, setMetricsClickRateLoading] = useState(true);
  const [metricsOptoutRateLoading, setMetricsOptoutRateLoading] = useState(true);
  const [metricsClickRate, setMetricsClickRate] = useState<IRecurringCampaignMetricsClickRate>();
  const [metricsOptoutRate, setMetricsOptoutRate] = useState<IRecurringCampaignMetricsOptoutRate>();
  const [metricsDeliveredRate, setMetricsDeliveredRate] = useState<IRecurringCampaignMetricsDeliveredRate>();
  const [exportClickRateLoading, setExportClickRateLoading] = useState(false);
  const [exportDeliverabilityRateLoading, setExportDeliverabilityRateLoading] = useState(false);
  const [exportOptoutRateLoading, setExportOptoutRateLoading] = useState(false);

  const [{ data: customDataPointsData, loading: customDataPointsLoading }] = useCustomDataPoints({ personalized: true, skip: 0, take: 1000, isActive: true });
  const [tableOptions, setTableOptions] = useState({
    ...defaultRecurringCampaignCampaignsTableOptions,
  } as ISearchRequest);
  const [{ data: campaignsData, loading: campaignsLoading }, refetchCampaigns] = useRecurringCampaignCampaigns(id!, { ...tableOptions });

  const customDataPoints = customDataPointsData?.records ?? [];
  const isActive = [RecurringCampaignStatusEnum.ACTIVE, RecurringCampaignStatusEnum.SCHEDULING].includes(
    recurringCampaign?.status ?? RecurringCampaignStatusEnum.COMPLETE
  );



  useEffect(() => {
    if (id) {
      getRecurringCampaign(id);
    }
  }, [id]);

  useEffect(() => {
    if (id && showMetrics && !metricsClickRate) {
      getRecurringCampaignClickRate(id);
    }
    if (id && showMetrics && !metricsOptoutRate) {
      getRecurringCampaignOptoutRate(id);
    }
    if (id && showMetrics && !metricsDeliveredRate) {
      getRecurringCampaignDeliveredRate(id);
    }
  }, [showMetrics]);

  const handleRecurringCampaignPause = async () => {
    try {
      setPauseLoading(true);
      await axiosPatch(`/recurring-campaigns/${id}`, {
        status: isActive ? RecurringCampaignStatusEnum.PAUSED : RecurringCampaignStatusEnum.ACTIVE
      });
      await getRecurringCampaign(id ?? '');

      addNotification({
        header: !isActive ? 'Campaign resumed successfully' : 'Campaign paused successfully',
      });

    } catch (err) {
      console.error(err);
    } finally {
      setPauseLoading(false);
    }
    setPauseLoading(false);
  };

  const getRecurringCampaign = async (id: string) => {
    const recurringCampaign = await axiosGet<IHttpResponse<IRecurringCampaign>>(`/recurring-campaigns/${id}`);
    setRecurringCampaign(recurringCampaign.data);

    const testMessage = await axiosGet<IHttpResponse<ITestMessageResponse[]>>(`/recurring-campaigns/test-messages/${id}`);
    const fixedTestmessage = testMessage.data.map((testMessage) => {
      return {
        ...testMessage,
        errorCode: testMessage.errorCode !== undefined ? testMessage.errorCode : '-',
        errorType: testMessage.errorType !== null ? testMessage.errorType : '-',
        providerErrorCode: testMessage.providerErrorCode !== undefined ? testMessage.providerErrorCode : '-',
        providerErrorType: testMessage.providerErrorType !== null ? testMessage.providerErrorType : '-',
      };
    });
    setRecurringCampaignTestMessage(fixedTestmessage);

    const [client, providerName] = await Promise.all([
      axiosGet<IHttpResponse<IRecurringCampaign>>(`/clients/${recurringCampaign.data.clientId}`),
      axiosGet<IHttpResponse<string>>(`/providers/${recurringCampaign.data.providerId}`),
    ]);
    setClientName(client.data.name);
    setProviderName(convertProviderName(providerName.data));
  };

  const getRecurringCampaignClickRate = async (id: string) => {
    try {
      setMetricsClickRateLoading(true);

      const metrics = await axiosGet<IHttpResponse<IRecurringCampaignMetricsClickRate>>(`/recurring-campaigns/${id}/click-rate`);
      setMetricsClickRate(metrics.data);
    } catch (e) {
      addNotification({
        header: 'Failed to load click rate',
        type: NotificationType.FAILURE
      });
      setMetricsClickRate({
        clickRate: null
      });
    } finally {
      setMetricsClickRateLoading(false);
    }
  };

  const getRecurringCampaignOptoutRate = async (id: string) => {
    try {
      setMetricsOptoutRateLoading(true);

      const metrics = await axiosGet<IHttpResponse<IRecurringCampaignMetricsOptoutRate>>(`/recurring-campaigns/${id}/optout-rate`);
      setMetricsOptoutRate(metrics.data);
    } catch (e) {
      addNotification({
        header: 'Failed to load opt-out rate',
        type: NotificationType.FAILURE
      });
      setMetricsOptoutRate({
        optoutRate: null
      });
    } finally {
      setMetricsOptoutRateLoading(false);
    }
  };

  const getRecurringCampaignDeliveredRate = async (id: string) => {
    try {
      setMetricsDeliveredRateLoading(true);

      const metrics = await axiosGet<IHttpResponse<IRecurringCampaignMetricsDeliveredRate>>(`/recurring-campaigns/${id}/delivered-rate`);
      setMetricsDeliveredRate(metrics.data);
    } catch (e) {
      addNotification({
        header: 'Failed to load delivered rate',
        type: NotificationType.FAILURE
      });
      setMetricsDeliveredRate({
        deliveredRate: null
      });
    } finally {
      setMetricsDeliveredRateLoading(false);
    }
  };

  const handleRecurringCampaignDelete = async () => {
    setPauseLoading(true);
    try {
      await axiosPatch(`/recurring-campaigns/${id}/delete`, {});
      addNotification({
        header: 'Recurring Campaign Deleted'
      });
      navigate('/recurring-campaigns/');
    } catch (err) {
      addNotification({
        header: 'Recurring Campaign Deleted Failed',
        type: NotificationType.FAILURE
      });
    }
    finally {
      setPauseLoading(false);
    }
  };

  const convertProviderName = (providerName: string) => {
    switch (providerName) {
      case ProviderName.TELNYX_TOLL_FREE:
      case ProviderName.BANDWIDTH_TOLL_FREE:
        return 'Toll Free';
      case ProviderName.TELNYX_10DLC:
      case ProviderName.BANDWIDTH_10DLC:
      case ProviderName.SINCH_10DLC:
        return '10DLC';
      case ProviderName.SINCH_SHORT_CODE:
        return 'Short Code';
      default:
        return '-';
    }
  };

  const getScheduleMessage = (recurringCampaign: IRecurringCampaign) => {
    const audiences = recurringCampaign.audiences ?? [];
    const sendingStartsAt = recurringCampaign.sendingStartsAt;
    const startsAt = recurringCampaign.startsAt;
    const endsAt = !recurringCampaign.hasNoEndDate ? recurringCampaign.endsAt : undefined;
    const hasNoEndDate = recurringCampaign?.hasNoEndDate;

    if (isEmpty(audiences) || !sendingStartsAt || !startsAt) {
      return '';
    }

    const audienceNames = audiences?.map(audience => audience?.name) ?? [];
    const audienceNamesMessage = getOxfordCommaString(audienceNames);

    const startDate = new Date(startsAt).toLocaleDateString('en-US');
    const time = new Date(sendingStartsAt).toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric'
    });

    if (endsAt && !hasNoEndDate) {
      const endDate = new Date(endsAt).toLocaleDateString('en-US');
      return `Your messages are scheduled to send to ${audienceNamesMessage} at ${time} everyday, from ${startDate} to ${endDate}.`;
    } else {
      return `Your messages are scheduled to send to ${audienceNamesMessage} at ${time} everyday, from ${startDate}.`;
    }
  };

  const handleExportLinkClicks = async () => {
    setExportClickRateLoading(true);
    try {
      await axiosPost<IHttpResponse<IRecurringCampaignMetricsDeliveredRate>>(`/recurring-campaigns/export-click-rate`, { recurringCampaignId: recurringCampaign?.id });
      addNotification({
        header: 'An email has been sent with the exported link click rates.',
        type: NotificationType.SUCCESS
      });
    } catch (e) {
      addNotification({
        header: 'Unable to export the link click rate for this recurring campaign.',
        type: NotificationType.FAILURE
      });
    } finally {
      setExportClickRateLoading(false);
    }
  };

  const handleExportOptouts = async () => {
    setExportOptoutRateLoading(true);
    try {
      await axiosPost<IHttpResponse<IRecurringCampaignMetricsDeliveredRate>>(`/recurring-campaigns/export-optout-rate`, { recurringCampaignId: recurringCampaign?.id });
      addNotification({
        header: 'An email has been sent with the exported optout rates.',
        type: NotificationType.SUCCESS
      });
    } catch (e) {
      addNotification({
        header: 'Unable to export the optout rate for this recurring campaign.',
        type: NotificationType.FAILURE
      });
    } finally {
      setExportOptoutRateLoading(false);
    }
  };

  const handleExportDeliverability = async () => {
    setExportDeliverabilityRateLoading(true);
    try {
      await axiosPost<IHttpResponse<IRecurringCampaignMetricsDeliveredRate>>(`/recurring-campaigns/export-delivered-rate`, { recurringCampaignId: recurringCampaign?.id });
      addNotification({
        header: 'An email has been sent with the exported deliverability rates.',
        type: NotificationType.SUCCESS
      });
    } catch (e) {
      addNotification({
        header: 'Unable to export the deliverability rate for this recurring campaign.',
        type: NotificationType.FAILURE
      });
    } finally {
      setExportDeliverabilityRateLoading(false);
    }
  };

  const columns = getCampaignColumns(
    {
      name: (item: IRecurringCampaignInstanceOverview) => {
        navigate(`/recurring-campaigns/${id}/campaigns/${item.id}`);
      },
    },
    {
      name: (item: IRecurringCampaignInstanceOverview) => {
        if (!item) {
          return;
        }

        return <>
          <div className='mb-2 text-lg text-sky-600 hover:text-sky-900 hover:underline break-words whitespace-normal max-w-lg'>{item.name}</div>
          <div className='flex flex-row items-center text-xs text-medium-gray fill-medium-gray'>
            <ClockIcon width={15} className='mr-2' />
            <span>{format(new Date(item.startsAt), 'Pp')}</span>
          </div>
        </>;
      },
      deliveredProgress: (item: IRecurringCampaignInstanceOverview) => <CampaignDeliveredProgressBar campaign={item as unknown as ICampaignEntity} />,
      clickRate: (item: IRecurringCampaignInstanceOverview) => (item?.clickRate !== null && item?.clickRate !== undefined && isFinite(item?.clickRate)) ? `${item.clickRate.toFixed(1)}%` : '0%',
      optoutRate: (item: IRecurringCampaignInstanceOverview) => (item?.optoutRate !== null && item?.optoutRate !== undefined && isFinite(item?.optoutRate)) ? `${item.optoutRate.toFixed(1)}%` : '0%',
      status: (item: IRecurringCampaignInstanceOverview) => !item?.isActive ? 'Paused' : (item?.status ? item.status.charAt(0) + item.status.slice(1).toLowerCase() : ''),
    },
    [
      {
        label: 'View Campaign',
        action: async (row: IRecurringCampaignInstanceOverview) => {
          navigate(`/recurring-campaigns/${id}/campaigns/${row.id}`);
        },
        hidden: false
      },
      {
        label: 'Pause',
        action: async (row: IRecurringCampaignInstanceOverview) => {
          setIsCampaignActive(row.isActive);
          setShowPauseModal(true);
          setCampaignId(row.id);
        },
        hidden: (item: IRecurringCampaignInstanceOverview) => [CampaignStatusEnum.DELIVERED, CampaignStatusEnum.FAILED, CampaignStatusEnum.SENT].includes(item?.status as CampaignStatusEnum) || !item.isActive
      },
      {
        label: 'Resume',
        action: async (row: IRecurringCampaignInstanceOverview) => {
          setIsCampaignActive(row.isActive);
          setShowPauseModal(true);
          setCampaignId(row.id);
        },
        hidden: (item: IRecurringCampaignInstanceOverview) => [CampaignStatusEnum.DELIVERED, CampaignStatusEnum.FAILED, CampaignStatusEnum.SENT].includes(item?.status as CampaignStatusEnum) || item.isActive
      }]
  );

  const handleRefetch = async () => {
    try {
      await refetchCampaigns();
    } catch (error) { }
  };

  const handleCampaignPauseResume = async () => {
    try {
      setIsCampaignPauseLoading(true);

      const request: UpdateCampaignRequest = { isActive: !isCampaignActive };
      await axiosPost(`/campaigns/${campaignId}`, request);
      addNotification({
        header: !isCampaignActive ? 'Campaign resumed successfully' : 'Campaign paused successfully',
      });

    } catch (error) {
      console.error(error);
    } finally {
      setIsCampaignPauseLoading(false);
    }
  };

  return (
    <div className='-mx-8'>
      <div className="px-28">
        <h1 className="flex items-center text-black basis-full">
          <div className='flex flex-row items-center justify-center'>
            <div>{recurringCampaign?.name}</div>
          </div>
          <Button className="ml-auto" onClick={() => navigate('/recurring-campaigns/')} variant={ButtonVariantEnum.SECONDARY}>
            BACK
          </Button>
        </h1>
        {recurringCampaign && (
          <h3 className="mt-2 font-semibold text-wc-blue">
            {getScheduleMessage(recurringCampaign)}
          </h3>
        )}
      </div>
      <div className="flex items-center pt-8 space-x-4 px-28">
        <h4 className="text-dark-gray">View:</h4>
        <Chip color={'bg-glacier'} active={!showMetrics} onClick={() => setShowMetrics(false)}>
          Campaign Details
        </Chip>
        <Chip color={'bg-glacier'} active={showMetrics} onClick={() => setShowMetrics(true)}>
          Performance Metrics
        </Chip>
      </div>
      {!showMetrics ? (
        <div className="flex pb-4 mt-8 px-28">
          <div className="basis-2/3">
            <div className="space-y-8">
              {/* HEADER */}
              <div className="flex">
                <h2>Campaign Details</h2>
              </div>
              {/* BASIC INFO */}
              <div className="flex details-row">
                <Static shimmer={10} title="CLIENT">
                  {clientName}
                </Static>
                <Static shimmer={10} title="STATUS">
                  {recurringCampaign?.status ? recurringCampaign.status.charAt(0) + recurringCampaign.status.substring(1).toLowerCase() : ''}
                </Static>
              </div>
              {/* START/END DATE */}
              <div className="flex details-row">
                <Static shimmer={10} title="START DATE">
                  {recurringCampaign?.startsAt ? moment(recurringCampaign?.startsAt).format('L, LT') : ''}
                </Static>
                <Static shimmer={10} title="END DATE">
                  {recurringCampaign?.endsAt ? (recurringCampaign?.hasNoEndDate ? 'No End Date' : moment(recurringCampaign?.endsAt).format('L, LT')) : ''}
                </Static>
              </div>
              {/* MESSAGE/NUMBER TYPE */}
              <div className="flex details-row">
                <Static shimmer={10} title="MESSAGE">
                  {recurringCampaign?.messageType}
                </Static>
                <Static shimmer={10} title="NUMBER">
                  {providerName}
                </Static>
              </div>
              {/* LISTS */}
              <div className="flex details-row">
                <Static shimmer={10} title="Audiences">
                  {recurringCampaign?.audiences?.length > 0 && (
                    <ol>
                      {recurringCampaign?.audiences?.map((cl, index) => (
                        <li key={cl.id}>{index + 1}. {cl.name}</li>
                      ))}
                    </ol>
                  )}
                </Static>
                <Static shimmer={10} title="Suppressions">
                  {recurringCampaign?.suppressions?.length > 0 ? (
                    <ol>
                      {recurringCampaign?.suppressions?.map((cl, index) => (
                        <li key={cl.id}>{index + 1}. {cl.name}</li>
                      ))}
                    </ol>
                  ) : (<ol>-</ol>)
                  }
                </Static>
                <Static shimmer={10} title="EXTERNAL ID">
                  {!!recurringCampaign && (recurringCampaign?.externalId || '-')}
                </Static>
              </div>
              {/* CLICK TRACKING */}
              <div className="flex flex-wrap">
                <div className="basis-1/2">
                  <GlacierCheckbox
                    id="click-tracking"
                    label="Enable Click Tracking"
                    readOnly
                    checked={recurringCampaign?.clickTrackingEnabled ?? false}
                  />
                </div>
                <div className="basis-1/2">
                </div>
                {recurringCampaign?.clickTrackingEnabled && (
                  <>
                    <div className="mt-4 basis-1/2">
                      <Static shimmer={10} title="URL">
                        {recurringCampaign?.url}
                      </Static>
                    </div>
                    <div className="mt-4 basis-1/2">
                      <Static shimmer={10} title="DOMAIN">
                        {recurringCampaign.domain}
                      </Static>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-auto">
            <MessagePreview campaign={recurringCampaign} domain={recurringCampaign?.domain} customDataPoints={customDataPoints} />
            {recurringCampaign?.messageType === MessageTypeEnum.MMS && (
              <div className="flex">
                <div className="pl-8 mx-auto mt-1">
                  <a href={recurringCampaign?.mediaUrl!} target="_blank" download className="text-wc-blue hover:underline">
                    Download Media File
                  </a>
                </div>
              </div>
            )}
            <div className="pl-8 mx-auto mt-2 body-text-small">
              Segments: {recurringCampaign?.segmentsCount} | Characters: {recurringCampaign?.charactersCount}/1000
            </div>
            <div className="pl-8 mx-auto mt-2 body-text-small">
            </div>
            {/* PAUSE CAMPAIGN */}
            {(!!recurringCampaign && recurringCampaign.status === RecurringCampaignStatusEnum.PAUSED) ?
              (<div className="flex items-center justify-between pl-8 mx-auto mt-4 space-x-4">
                <Button
                  onClick={() => setShowModal(true)}
                  isLoading={pauseLoading}
                  variant={ButtonVariantEnum.SECONDARY}
                  disabled={!recurringCampaign}
                >
                  RESUME
                </Button>

                <Button
                  onClick={() => setshowDeleteModal(true)}
                  isLoading={pauseLoading}
                  variant={ButtonVariantEnum.DELETE}
                  disabled={!recurringCampaign}
                >
                  DELETE
                </Button>
              </div>) :
              (<div className="flex flex-col pl-8 mx-auto mt-4 space-y-4">
                <Button
                  onClick={() => setShowModal(true)}
                  isLoading={pauseLoading}
                  variant={ButtonVariantEnum.SECONDARY}
                  disabled={!recurringCampaign}
                >
                  PAUSE RECURRING CAMPAIGN
                </Button>
              </div>)

            }
          </div>
        </div>
      ) : (
        <>
          <div className="mt-8 mb-4 px-28">
            <div className="w-7/12">
              {/* HEADER */}
              <div className="flex mb-8">
                <h2>Performance Metrics Overview</h2><Info className="mt-2 ml-2" bubbleWrapperClassName='w-[12rem]' text="Opt-out metrics only include data from the last 7 days." />
              </div>
              {/* METRICS L1 */}
              <div>
                <div className="flex justify-between mb-8">
                  <MetricCard
                    title="DELIVERABILITY RATE"
                    _export={!!recurringCampaign}
                    loading={metricsDeliveredRateLoading}
                    exportLoading={exportDeliverabilityRateLoading}
                    metric={metricsDeliveredRate?.deliveredRate}
                    onExport={() => handleExportDeliverability()}
                  />
                  <MetricCard
                    title="CLICK RATE"
                    _export={!!recurringCampaign}
                    metric={metricsClickRate?.clickRate}
                    onExport={() => handleExportLinkClicks()}
                    loading={metricsClickRateLoading}
                    exportLoading={exportClickRateLoading}
                  />
                  <MetricCard
                    title="OPT-OUT RATE"
                    _export={!!recurringCampaign}
                    loading={metricsOptoutRateLoading}
                    exportLoading={exportOptoutRateLoading}
                    metric={metricsOptoutRate?.optoutRate}
                    onExport={() => handleExportOptouts()}
                  />
                </div>
              </div>
              <MessageStatus testMessage={recurringCampaignTestMessage ?? []} />
            </div>

          </div>
          <div>
            <div className='flex mb-4 px-28 mt-8'><h2>Campaign Metrics</h2><Info className="z-10 mt-2 ml-2" bubbleWrapperClassName='w-[12rem]' text="Opt-out rate will only be populated for campaigns run within last 7 days." /></div>
            <TableList
              cssOverride={{
                wrapper: "px-[7rem]"
              }}
              columns={columns}
              items={campaignsData?.records ?? []}
              totalCount={campaignsData?.totalCount ?? 0}
              limit={5}
              paginate
              onPaginate={(page) => {
                setTableOptions((prevState) => ({
                  ...prevState,
                  pagination: {
                    ...prevState.pagination,
                    skip: page * prevState.pagination.take,
                  },
                }));
              }}
              loading={campaignsLoading}
              actions={[
                {
                  label: 'View',
                  action: async (row: IRecurringCampaignInstanceOverview) => {
                    navigate(`/recurring-campaigns/${id}/campaigns/${row.id}`);
                  },
                  hidden: false
                },
                {
                  label: 'Pause',
                  action: async (row: IRecurringCampaignInstanceOverview) => {
                    setIsCampaignActive(row.isActive);
                    setShowPauseModal(true);
                    setCampaignId(row.id);
                  },
                  hidden: (item: IRecurringCampaignInstanceOverview) => [CampaignStatusEnum.DELIVERED, CampaignStatusEnum.FAILED, CampaignStatusEnum.SENT].includes(item?.status as CampaignStatusEnum) || !item.isActive
                },
                {
                  label: 'Resume',
                  action: async (row: IRecurringCampaignInstanceOverview) => {
                    setIsCampaignActive(row.isActive);
                    setShowPauseModal(true);
                    setCampaignId(row.id);
                  },
                  hidden: (item: IRecurringCampaignInstanceOverview) => [CampaignStatusEnum.DELIVERED, CampaignStatusEnum.FAILED, CampaignStatusEnum.SENT].includes(item?.status as CampaignStatusEnum) || item.isActive
                },
              ]}
            />
          </div>
        </>
      )}

      <RecurringCampaignPauseResumeModal
        isCampaignActive={isActive ?? false}
        showModal={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        onSuccess={() => {
          handleRecurringCampaignPause();
          setShowModal(false);
        }}
        onCancel={() => {
          setShowModal(false);
        }}
      />

      <RecurringCampaignDeleteModal
        showModal={showDeleteModal}
        onClose={() => {
          setshowDeleteModal(false);
        }}
        onSuccess={() => {
          handleRecurringCampaignDelete();
          setshowDeleteModal(false);
        }}
        onCancel={() => {
          setshowDeleteModal(false);
        }}
      />

      <CampaignPauseResumeModal
        isCampaignActive={isCampaignActive}
        showModal={showPauseModal}
        isLoading={isCampaignPauseLoading}
        onClose={() => {
          setShowPauseModal(false);
        }}
        onSuccess={async () => {
          await handleCampaignPauseResume();
          setShowPauseModal(false);
          await refetchCampaigns();
        }}
        onCancel={() => {
          setShowPauseModal(false);
        }}
      />
    </div>);
};



export default RecurringCampaignsDetails;
