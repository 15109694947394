import { Link } from 'react-router-dom';

export interface INavItem {
  name: string;
  icon?: any; // (props: SVGProps<SVGSVGElement>) => JSX.Element;
  href?: string;
  visible?: boolean;
  children?: INavItem[];
}

export interface IMenuItem {
  item: INavItem;
  active: boolean;
  open?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  discRef?: React.MutableRefObject<HTMLButtonElement | undefined>;
  close?: (focusableElement?: HTMLElement | React.MutableRefObject<HTMLElement | null>) => void;
}

export const MenuItem = ({ item, active }: IMenuItem) => (
  <Link
    id={`nav-${item?.name?.toLocaleLowerCase().split(' ').join('-')}`}
    key={item?.name}
    to={item?.href || '/'}
    style={{ width: '100%' }}
    className={`menu-item text-nowrap ${!item.icon && 'menu-sub-item'} ${active && item.icon && 'menu-item-active'} ${active && !item.icon ? 'menu-sub-item-active' : 'text-white'
      }`}
  >
    {item.icon && (
      <item.icon className={`flex-shrink-0 ${active && 'text-sky-800 dark:text-slate-900'}`} aria-hidden="true" />
    )}
    {item?.name}
  </Link>
);