import { Disclosure } from '@headlessui/react';
import { useEffect, useRef } from 'react';
import { Dropdown as DropdownIcon } from '../Icons/Dropdown';
import { IMenuItem } from './MenuItem';

export const MenuButton = ({ item, active, open, onClick, discRef, close }: IMenuItem) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    // Logic for closing active/open disclosures when another is clicked (don't touch this ever)
    if (discRef && open) { // Are we opening a disclosure?
      if (discRef.current !== buttonRef.current) { // Is the disclosure we're opening different than the referenced one
        discRef.current?.click(); // If so click the referenced disclosure (this will close it)
        discRef.current = buttonRef.current!; // Set the disclosure we're opening to the referenced one
      }
    } else if (discRef && discRef.current === buttonRef.current) { // Are we closing the referenced disclosure
      discRef.current = undefined; // Reset the referenced disclosure so we don't double click it when we open it again
    }
  }, [open]);

  return <Disclosure.Button className={`menu-item text-white`} onClick={onClick} ref={buttonRef}>
    <item.icon
      className={`mr-3 flex-shrink-0 ${item.href === location.pathname && 'text-sky-800 dark:text-slate-900'}`}
      aria-hidden="true"
    />
    <div className="flex w-full">
      <span className='pr-2'>{item.name}</span>
      <div className={`w-full mt-px flex justify-center ${open ? 'rotate-180' : ''}`}>
        <DropdownIcon />
      </div>
    </div>
  </Disclosure.Button>;
};
