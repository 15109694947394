import { Button, ButtonVariantEnum, Chip, GlacierCheckbox, Info, Shimmer, ShimmerType, Static, StaticTableList } from '@Wonder-Cave/ui';
import { CampaignStatusEnum, MessageTypeEnum, ProviderName } from '@shared/enums';
import { Auth0Role, IHttpResponse, INewCampaignDetails, IRecurringCampaign, ITestMessageResponse, UpdateCampaignRequest } from '@shared/models';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosDelete, axiosGet, axiosPost } from '../../authAxios';
import { NotificationType, useNotifications } from '../../contexts/NotificationContext';
import { useCustomDataPoints } from '../../hooks/useCustomDataPoints';
import useRoles from '../../hooks/useRoles';
import MessagePreview from '../shared/Form/MessagePreview';
import { Radio } from '../shared/Icons/Radio';
import CampaignDeleteModal from './CampaignDeleteModal';
import CampaignPauseResumeModal from './CampaignPauseResumeModal';

interface IMetricCardProps {
  title: string;
  metric: number;
  loading: boolean;
  footer?: React.ReactNode;
  _export?: boolean;
  onExport?: () => void;
  exportLoading?: boolean;
  flat?: boolean;
  disabled?: boolean;
}

const MetricCard = ({ title, loading, metric, footer, _export, onExport, flat, exportLoading, disabled = false }: IMetricCardProps) => {
  return (
    <div
      className={`${flat ? '' : 'rounded-2.5xl'} bg-white flex flex-col items-center p-4 space-y-4 ${flat ? '' : 'w-44'
        }`}
      style={{ boxShadow: flat ? '' : '0px 0px 70px 0px #E9EBF0' }}
    >
      <h5 className="text-medium-gray">{title}</h5>
      {loading ? (
        <Shimmer type={ShimmerType.CUSTOM} className="w-16 h-4" />
      ) : (
        <div>
          {isFinite(metric) ? (flat ? metric : metric.toFixed(1)) : 0}
          {flat ? '' : '%'}
        </div>
      )}
      {!!onExport ? (
        <h4
          onClick={_export && !exportLoading && !disabled ? onExport : () => { }}
          className={`${(_export && !exportLoading && !disabled)
            ? 'cursor-pointer text-wc-blue decoration-wc-blue decoration-2 hover:underline'
            : `cursor-default text-medium-gray ${exportLoading ? 'animate-pulse' : ''}`
            }`}
        >
          <div className='flex items-center'>
            Export Data
            {disabled && <Info className="ml-1" text={`Unable to export ${title} data as it has been more than 30 days since campaign start date.`} />}
          </div>
        </h4>
      ) : (
        footer
      )}
    </div>
  );
};

export const CampaignsDetails = () => {
  const { id, recurringId } = useParams<any>();
  const [campaign, setCampaign] = useState<any>();
  const [campaignPerforanceMetrics, setCampaignPerforanceMetrics] = useState<any>();
  const [campaignOptOut, setCampaignOptOut] = useState<any>();
  const [campaignReplys, setCampaignReplys] = useState<any>();
  const [campaignTestMessage, setCampaignTestMessage] = useState<any>();
  const [campaignErrors, setCampaignErrors] = useState<any>();
  const campaignId = id;
  const navigate = useNavigate();
  const [showMetrics, setShowMetrics] = useState(false);
  const [clientName, setClientName] = useState('');
  const [providerName, setProviderName] = useState('');
  const [pauseLoading, setPauseLoading] = useState(false);
  const [campaignLoading, setCampaignLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const { addNotification } = useNotifications();
  const isSuperAdmin = useRoles([Auth0Role.A2P_SUPER_ADMIN]);
  const [{ data: customDataPointsData, loading: customDataPointsLoading }] = useCustomDataPoints({ personalized: true, skip: 0, take: 1000, isActive: true });
  const customDataPoints = customDataPointsData?.records ?? [];
  const isProcessedCampaign = [
    CampaignStatusEnum.DELIVERED,
    CampaignStatusEnum.FAILED,
    CampaignStatusEnum.SENT
  ].includes(campaign?.status as CampaignStatusEnum);

  useEffect(() => {
    if (campaignId) {
      getCampaign(campaignId);
    }
  }, [campaignId]);

  const handleCampaignPauseResume = async () => {
    try {
      setPauseLoading(true);
      const request: UpdateCampaignRequest = { isActive: !campaign?.isActive };
      await axiosPost(`/campaigns/${campaign.id}`, request);
      addNotification({
        header: !campaign.isActive ? 'Campaign resumed successfully' : 'Campaign paused successfully',
      });
      getCampaign(campaignId ?? '');
    } catch (error) {
      console.error(error);
    } finally {
      setPauseLoading(false);
    }
  };

  const handleCampaignDelete = async () => {
    try {
      if (isProcessedCampaign) {
        addNotification({
          header: 'Unable to delete campaign as all messages have been sent',
          type: NotificationType.FAILURE
        });
      } else if (campaign?.totalMessagesSent > 0) {
        addNotification({
          header: 'Unable to delete campaign in progress',
          type: NotificationType.FAILURE
        });
      } else {
        setPauseLoading(true);
        await axiosDelete(`/campaigns/${campaign.id}`);
        addNotification({
          header: 'Campaign Deleted successfully',
        });
        getCampaign(campaignId ?? '');
      }
    } catch (error) {
      console.error(error);
      addNotification({
        header: `${error}`,
        type: NotificationType.FAILURE
      });
    } finally {
      setPauseLoading(false);
      navigate('/campaigns');
    }
  };

  const formatPhoneNumber = (phoneNumberString: string) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumberString;
  };

  const getCampaign = async (id: string) => {
    setCampaignLoading(true);
    const campaign = await axiosGet<IHttpResponse<INewCampaignDetails>>(`/campaigns/${id}`);
    setCampaign(campaign.data);


    const [client, providerName, performanceMetrics, optout, replys, testmessage, errors] = await Promise.all([
      axiosGet<IHttpResponse<IRecurringCampaign>>(`/clients/${campaign.data.clientId}`),
      axiosGet<IHttpResponse<string>>(`/providers/${campaign.data.providerId}`),
      axiosGet<IHttpResponse<string>>(`/campaigns-performance-metrics/${id}`),
      axiosGet<IHttpResponse<string>>(`/campaigns-optout/${id}`),
      axiosGet<IHttpResponse<string>>(`/campaigns-get-replys/${id}`),
      axiosGet<IHttpResponse<ITestMessageResponse[]>>(`/campaigns-test-messages/${id}`),
      axiosGet<IHttpResponse<ITestMessageResponse[]>>(`/campaigns-errors/${id}`),


    ]);
    setClientName(client.data.name);
    setProviderName(convertProviderName(providerName.data));
    setCampaignPerforanceMetrics(performanceMetrics.data);
    setCampaignOptOut(optout.data);
    setCampaignReplys(replys.data);

    const fixedTestmessage = testmessage.data.map((testMessage) => {
      return {
        ...testMessage,
        errorCode: testMessage.errorCode !== undefined ? testMessage.errorCode : '-',
        errorType: testMessage.errorType !== null ? testMessage.errorType : '-',
        providerErrorCode: testMessage.providerErrorCode !== undefined ? testMessage.providerErrorCode : '-',
        providerErrorType: testMessage.providerErrorType !== null ? testMessage.providerErrorType : '-',
      };
    });
    setCampaignTestMessage(fixedTestmessage);
    setCampaignErrors(errors.data);


    setCampaignLoading(false);
  };


  const convertProviderName = (providerName: string) => {
    switch (providerName) {
      case ProviderName.TELNYX_TOLL_FREE:
      case ProviderName.BANDWIDTH_TOLL_FREE:
        return 'Toll Free';
      case ProviderName.TELNYX_10DLC:
      case ProviderName.BANDWIDTH_10DLC:
      case ProviderName.SINCH_10DLC:
        return '10DLC';
      case ProviderName.SINCH_SHORT_CODE:
        return 'Short Code';
      default:
        return '-';
    }
  };

  const handleExportDeliverability = async () => {
    //throw new Error('Function not implemented.');
  };

  const handleExportLinkClicks = async () => {
    //throw new Error('Function not implemented.');
  };

  const handleExportOptouts = async () => {
    //throw new Error('Function not implemented.');
  };

  const handleExportConversations = async () => {
    //throw new Error('Function not implemented.');
  };

  return (<>   <div className="px-20">
    <h1 className="flex items-center text-black basis-full">
      <div className='flex flex-row items-center justify-center'>
        <div>{campaign?.name}</div>
      </div>
      <Button className="ml-auto" onClick={() => navigate(recurringId ? `/recurring-campaigns/${recurringId}` : '/campaigns')} variant={ButtonVariantEnum.SECONDARY}>
        BACK
      </Button>
    </h1>
    {/* Fix */}
    <h3 className="mt-2 font-semibold text-wc-blue">
      {campaign?.totalMessagesScheduled?.toLocaleString() ?? '-'} messages scheduled to send at {' '}
      {moment(campaign?.updatedAt).format('L, LT')}
    </h3>
  </div>
    <div className="flex items-center px-20 pt-8 space-x-4">
      <h4 className="text-dark-gray">View:</h4>
      <Chip color={'bg-glacier'} active={!showMetrics} onClick={() => setShowMetrics(false)}>
        Campaign Details
      </Chip>
      <Chip color={'bg-glacier'} active={showMetrics} onClick={() => setShowMetrics(true)}>
        Performance Metrics
      </Chip>
    </div>
    <div className="flex px-20 pb-4 mt-8">
      <div className="basis-2/3">
        {!showMetrics ? (
          <div className="space-y-8">
            {/* HEADER */}
            <div className="flex">
              <h2>Campaign Details</h2>
            </div>
            {/* BASIC INFO */}
            <div className="flex details-row">
              <Static shimmer={10} title="CLIENT">
                {clientName}
              </Static>
              <Static shimmer={10} title="STATUS">
                {campaign?.status.charAt(0) + campaign?.status.substring(1).toLowerCase()}
              </Static>
            </div>
            {/* START/END DATE */}
            <div className="flex details-row">
              <Static shimmer={10} title="START DATE">
                {campaign?.startsAt ? moment(campaign?.startsAt).format('L, LT') : ''}
              </Static>
              <Static shimmer={10} title="END DATE">
                {campaign?.endsAt ? moment(campaign?.endsAt).format('L, LT') : ''}
              </Static>
            </div>
            {/* MESSAGE/NUMBER TYPE */}
            <div className="flex details-row">
              <Static shimmer={10} title="MESSAGE">
                {campaign?.messageType}
              </Static>
              <Static shimmer={10} title="NUMBER">
                {providerName}
              </Static>
            </div>
            {/* LISTS */}
            <div className="flex details-row">
              <Static shimmer={10} title="Audiences">
                {campaign?.audiences?.length > 0 && (
                  <ol>
                    {campaign?.audiences?.map((cl, index) => (
                      <li key={cl.id}>{index + 1}. {cl.name}</li>
                    ))}
                  </ol>
                )}
              </Static>
              <Static shimmer={10} title="Suppressions">
                {campaign?.suppressions?.length > 0 ? (
                  <ol>
                    {campaign?.suppressions?.map((cl, index) => (
                      <li key={cl.id}>{index + 1}. {cl.name}</li>
                    ))}
                  </ol>
                ) : (<ol>-</ol>)
                }
              </Static>
              <Static shimmer={10} title="EXTERNAL ID">
                {!!campaign && (campaign?.externalId || '-')}
              </Static>
            </div>
            {/* CLICK TRACKING */}
            <div className="flex flex-wrap">
              <div className="basis-1/2">
                <GlacierCheckbox
                  id="click-tracking"
                  label="Enable Click Tracking"
                  readOnly
                  checked={campaign?.clickTrackingEnabled ?? false}
                />
              </div>
              <div className="basis-1/2">
              </div>
              {campaign?.clickTrackingEnabled && (
                <>
                  <div className="mt-4 basis-1/2">
                    <Static shimmer={10} title="URL">
                      {campaign?.url}
                    </Static>
                  </div>
                  <div className="mt-4 basis-1/2">
                    <Static shimmer={10} title="DOMAIN">
                      {campaign.domain}
                    </Static>
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="space-y-8">
            {/* HEADER */}
            <div className="flex">
              <h2>Performance Metrics</h2><Info className="mt-2 ml-2" bubbleWrapperClassName='w-[12rem]' text="opt-out metrics only include data from the last 7 days." />
            </div>
            {/* METRICS L1 */}
            <div>
              <div className="flex justify-between mb-8">
                <MetricCard
                  title="DELIVERABILITY RATE"
                  _export={!!campaign}
                  loading={campaignLoading}
                  exportLoading={false}
                  metric={((campaignPerforanceMetrics?.totalMessagesDelivered ?? 0) / (campaignPerforanceMetrics?.totalMessagesSent ?? 0)) * 100 || 0}
                  onExport={() => handleExportDeliverability()}
                />
                <MetricCard
                  title="CLICK RATE"
                  _export={!!campaign}
                  metric={((campaign?.linkClicks ?? 0) / (campaign?.totalMessagesDelivered ?? 0)) * 100 || 0}
                  onExport={() => handleExportLinkClicks()}
                  loading={campaignLoading}
                  exportLoading={false}
                />
                <MetricCard
                  title="OPT-OUT RATE"
                  _export={!!campaign}
                  loading={campaignLoading}
                  exportLoading={false}
                  metric={((campaignOptOut ?? 0) /
                    (campaign?.totalMessagesDelivered ?? 0)) * 100 || 0}
                  onExport={() => handleExportOptouts()}
                />
              </div>
              <div
                className="rounded-2.5xl bg-white flex items-center p-4 justify-between"
                style={{ boxShadow: '0px 0px 70px 0px #E9EBF0' }}
              >
                <h3 className="ml-8">Reply Rate</h3>
                <MetricCard
                  title="ALL RESPONSES"
                  flat
                  metric={campaignReplys ?? 0}
                  loading={campaignLoading}
                  _export={!!campaign}
                  onExport={() => handleExportConversations()}
                  exportLoading={false}
                />
                <MetricCard
                  title="REPLIES WITHOUT OPT-OUTS"
                  flat
                  _export={!!campaign}
                  onExport={() => handleExportConversations()}
                  loading={campaignLoading}
                  metric={(campaignReplys ?? 0) - (campaignOptOut ?? 0) || 0}
                  exportLoading={false}
                />
              </div>
            </div>
            {/* MESSAGE STATUS */}
            <div className="flex flex-wrap justify-between pt-12 pb-8 px-16 rounded-2.5xl bg-ice border-2 border-white">
              <h2 className="m-auto mb-2 text-left text-black basis-full">Message Status</h2>
              <h4 className='text-dark-grey'>Status: {campaign?.status.charAt(0) + campaign?.status.substring(1).toLowerCase()} </h4>
              <div>
                <div className="flex items-center">
                  {/* Sent / Delivered / Failed */}
                  <h5 className="min-w-[65px] text-right mt-px mr-2 text-dark-gray">DELIVERY</h5>
                  <div
                    className={`flex grow w-36 basis-1/2 h-2 overflow-hidden text-xs rounded justify-end ${campaign?.totalMessagesSent === 0 ? 'bg-light-gray' : 'bg-mint'
                      }`}
                  >
                    <>
                      {/* <div
                        style={{
                          width: `${Math.min(
                            Math.ceil(
                              ((campaign?.messagesDelivered ?? 0) / (campaign?.messagesDelivered ?? 100)) * 100
                            ),
                            100
                          )}%`,
                        }}
                        className={`shadow-none bg-grass`}
                      ></div> */}
                      <div
                        style={{
                          width: `${Math.min(
                            Math.ceil(((campaign?.totalMessagesFailed ?? 0) / (campaign?.totalMessagesSent ?? 100)) * 100),
                            100
                          )}%`,
                        }}
                        className={`flex flex-col justify-center text-center text-white shadow-none bg-red-400 whitespace-nowrap`}
                      ></div>
                    </>
                  </div>
                </div>
                <h5
                  className="mt-0.5 text-right truncate text-medium-gray dark:text-slate-400 basis-full"
                  title="Sent / Delivered / Failed"
                >
                  {campaign?.totalMessagesSent?.toLocaleString()} / {campaign?.totalMessagesDelivered?.toLocaleString()} /{' '}
                  {campaign?.totalMessagesFailed?.toLocaleString()}
                </h5>
              </div>
            </div>
            {!recurringId && campaignTestMessage?.length > 0 && (
              <div>
                <div className='flex'>
                  <h2 className='mb-1 mr-2'>Test Message Status  </h2>
                  <Radio />
                </div>

                <StaticTableList
                  className="mt-8 basis-full"
                  columns={[
                    {
                      headerName: 'NUMBER',
                      fieldName: 'toNumber',
                      renderColumn: (row) => (
                        <div className="flex items-center whitespace-nowrap">
                          {formatPhoneNumber(row.toNumber)}
                          {row.errorCode === '-' && (
                            <div className="mx-2">
                              <Radio />
                            </div>
                          )}
                        </div>
                      ),
                    },
                    {
                      headerName: 'CARRIER',
                      fieldName: 'carrierName',
                    },
                    {
                      headerName: 'ERROR CODE',
                      fieldName: 'errorCode',
                    },
                    {
                      headerName: 'ERROR TYPE',
                      fieldName: 'errorType',
                    },
                    ...(isSuperAdmin
                      ? [
                        {
                          headerName: 'PROVDER ERROR CODE',
                          fieldName: 'providerErrorCode',
                        },
                        {
                          headerName: 'PROVIDER ERROR TYPE',
                          fieldName: 'providerErrorType',
                        },
                      ]
                      : []),
                  ]}
                  data={campaignTestMessage} />
              </div>
            )}
            {campaignErrors?.errorCodes?.length > 0 && (
              <div className="flex flex-wrap justify-between pt-2 pb-2">
                <h2 className='text-orange-500'>Message Failure Log</h2>
                <StaticTableList
                  className="mt-8 basis-full"
                  columns={[

                    {
                      headerName: 'code',
                      fieldName: 'code',
                      renderColumn: (row) => (
                        <div className="flex items-center mr-5 whitespace-nowrap">
                          {(row.code)}

                        </div>
                      ),
                    },
                    {
                      headerName: 'COUNT',
                      fieldName: 'count',
                    },
                    {
                      headerName: 'ERROR TYPE',
                      fieldName: 'errorType',
                    },
                  ]}
                  data={campaignErrors?.errorCodes} />
              </div>
            )}
            {isSuperAdmin && campaignErrors?.internalErrorCodes?.length > 0 && (

              <div className="flex flex-wrap justify-between pt-2 pb-2">
                <h2 className='text-orange-500'>Admin Message Failure Log</h2>
                <StaticTableList
                  className="mt-8 basis-full"
                  columns={[

                    {
                      headerName: 'code',
                      fieldName: 'code',
                      renderColumn: (row) => (
                        <div className="flex items-center mr-10 whitespace-nowrap">
                          {(row.code)}

                        </div>
                      ),
                    },
                    {
                      headerName: 'COUNT',
                      fieldName: 'count',
                      renderColumn: (row) => (
                        <div className="flex items-center mr-16 whitespace-nowrap">
                          {(row.count)}

                        </div>
                      ),
                    },
                    {
                      headerName: 'ERROR TYPE',
                      fieldName: 'errorType',
                    },
                  ]}
                  data={campaignErrors?.internalErrorCodes} />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="flex flex-col ml-auto">
        <MessagePreview campaign={campaign} domain={campaign?.domain} customDataPoints={customDataPoints} />
        {campaign?.messageType === MessageTypeEnum.MMS && (
          <div className="flex">
            <div className="pl-8 mx-auto mt-1">
              <a href={campaign?.mediaUrl!} target="_blank" download className="text-wc-blue hover:underline">
                Download Media File
              </a>
            </div>
          </div>
        )}
        <div className="pl-8 mx-auto mt-2 body-text-small">
          Segments: {campaign?.segmentsCount} | Characters: {campaign?.charactersCount}/1000
        </div>
        <div className="pl-8 mx-auto mt-2 body-text-small">
        </div>
        {/* PAUSE CAMPAIGN */}
        <div className="flex items-center justify-between pl-8 mx-auto mt-4 space-x-4">
          {(!!campaign && !isProcessedCampaign) &&
            <Button
              onClick={() => setShowModal(true)}
              isLoading={pauseLoading}
              variant={ButtonVariantEnum.SECONDARY}
              disabled={!campaign}
            >
              {campaign?.isActive ? 'PAUSE CAMPAIGN' : 'RESUME CAMPAIGN'}
            </Button>
          }
          {((!!campaign && !campaign.isActive && !isProcessedCampaign && !(campaign?.totalMessagesSent > 0))) &&
            <Button
              onClick={() => setshowDeleteModal(true)}
              isLoading={pauseLoading || campaignLoading}
              variant={ButtonVariantEnum.DELETE}
              disabled={!campaign}
            >
              DELETE
            </Button>
          }

        </div>
      </div>
    </div>
    <CampaignPauseResumeModal
      isCampaignActive={campaign?.isActive}
      showModal={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      onSuccess={() => {
        handleCampaignPauseResume();
        setShowModal(false);
      }}
      onCancel={() => {
        setShowModal(false);
      }}
    />

    <CampaignDeleteModal
      showModal={showDeleteModal}
      onClose={() => {
        setshowDeleteModal(false);
      }}
      onSuccess={() => {
        handleCampaignDelete();
        setshowDeleteModal(false);
      }}
      onCancel={() => {
        setshowDeleteModal(false);
      }}
    />

  </>);
};



export default CampaignsDetails;
