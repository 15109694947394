import { User } from '@auth0/auth0-react';
import { getAllowedSubDomains, getUserMemberships } from 'apps/a2p-portal/src/providers/auth0.provider';
import { SubDomain, getSubDomainLabel } from 'apps/a2p-portal/src/providers/sub-domain.service';
import { IDropdownValue } from '../Dropdown';
import AutocompleteBlack from './AutocompleteBlack';

interface ISubDomainDropdownProps {
  value: IDropdownValue | undefined;
  onChange: (newValue?: IDropdownValue | undefined) => void;
  onBlur?: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
  auth0User: User | undefined;
}

const SubDomainDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  auth0User,
}: ISubDomainDropdownProps): JSX.Element => {
  const memberships = getUserMemberships(auth0User);
  const options = getAllowedSubDomains(memberships).map((domain) => getOption(domain));

  return (
    <AutocompleteBlack
      label="Organization"
      value={value!}
      options={options}
      onChange={onChange}
      onBlur={onBlur}
      error={errorMessage}
      disabled={disabled}
      labelColor="text-white"
    />
  );
};

function getOption(domain?: SubDomain): IDropdownValue {
  return {
    label: getSubDomainLabel(domain),
    value: domain,
  };
}

export default SubDomainDropdown;
