import { MessageTypeEnum, RecurringCampaignCooldownPeriodEnum, RecurringCampaignFrequencyEnum } from '@shared/enums';
import { ICustomDataPointEntity, IDomainsEntity } from '@shared/models';
import { checkSHAFTCompliance, getCurrentDate, getSMSMessageSegmentCount } from '@shared/services';
import { FormikErrors, FormikTouched } from 'formik';
import * as yup from 'yup';
import { IRecurringCampaignForm, IRecurringCampaignsBuildForm } from '../../types';

export interface IBuildProps {
  values?: IRecurringCampaignForm;
  errors?: FormikErrors<IRecurringCampaignForm>;
  touched?: FormikTouched<IRecurringCampaignForm>;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldTouched?: (field: string, touched: boolean) => void;
  setFieldError?: (field: string, msg: string | undefined) => void;
  handleChange?: () => void;
  handleBlur?: () => void;
  onSubmit: (formikProps) => void;
  setLeads: (leads: any) => void;
  setLoading: (value: boolean) => void;
  leads: number;
  submissionError?: string;
  domains: IDomainsEntity[];
  customDataPoints: ICustomDataPointEntity[];
  readOnly?: boolean;
}

export const recurringCampaignBuildFormSchema: yup.SchemaOf<IRecurringCampaignsBuildForm> = yup.object().shape({
  message: yup
    .string()
    .test('notContainsPipe', 'Message must not contain "|" symbol', (message) => !(message && message.includes('|')))
    .when('messageType', {
      is: (messageType: MessageTypeEnum) => messageType === MessageTypeEnum.MMS,
      then: yup.string().max(1600, 'Message cannot be longer than 1600 characters'),
      otherwise: yup.string().max(1000, 'Message cannot be longer than 1000 characters'),
    })
    .test('containsLink', 'Message must contain {{link}}', (message: string | undefined, context): boolean => {
      return context.parent.clickTrackingEnabled && message ? message?.toLowerCase()?.includes('{{link}}') : true;
    })
    .test('notContainsLink', 'Message must not contain {{link}}', (message: string | undefined, context): boolean => {
      return !context.parent.clickTrackingEnabled && message ? !message?.toLowerCase()?.includes('{{link}}') : true;
    })
    .test('shaftCompliance', 'Please update your message to remove banned words.', (message: string | undefined, context): boolean => {
      return checkSHAFTCompliance(message);
    })
    .test('segmentCount', 'Segment count must not be larger than 10', (message: string | undefined, context): boolean => {
      if (context.parent.messageType === MessageTypeEnum.SMS) {
        return getSMSMessageSegmentCount(message ?? '') <= 10;
      } else {
        return true;
      }
    })
    .defined('Required'),

  clickTrackingEnabled: yup.boolean(),

  url: yup
    .string()
    .when('clickTrackingEnabled', {
      is: (clickTrackingEnabled: boolean) => clickTrackingEnabled,
      then: yup.string().test('apostrophe', 'URL cannot have an apostrophe', (value) => !value?.includes('\'')).url('Invalid URL format').defined('Required'),
    }),

  domainId: yup
    .string()
    .when('clickTrackingEnabled', {
      is: (clickTrackingEnabled: boolean) => clickTrackingEnabled,
      then: yup.string().defined('Required'),
    }).nullable(),

  hasNoEndDate: yup.boolean(),

  startsAt: yup
    .date()
    .min(getCurrentDate({ clearTime: true }), 'Campaign cannot start in the past')
    .typeError('Invalid Date')
    .defined('Required'),

  endsAt: yup
    .date()
    .when('hasNoEndDate', {
      is: (hasNoEndDate: boolean) => !hasNoEndDate,
      then: yup.date().min(yup.ref('startsAt'), 'End date cannot be before Start date').typeError('Invalid Date').defined('Required'),
    }),

  sendingStartsAt: yup
    .date()
    .typeError('Invalid Date')
    .defined('Required'),

  sendingEndsAt: yup
    .date()
    .typeError('Invalid Date')
    .defined('Required'),

  frequency: yup
    .mixed<RecurringCampaignFrequencyEnum>().oneOf([RecurringCampaignFrequencyEnum.DAILY])
    .defined('Required')
    .required('Required'),

  cooldownPeriodCount: yup
    .number()
    .required(),

  cooldownPeriod: yup
    .mixed<RecurringCampaignCooldownPeriodEnum>().oneOf([RecurringCampaignCooldownPeriodEnum.MONTHS])
    .defined('Required')
    .required('Required'),
});
