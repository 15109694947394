import { Combobox, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Tick as TickIcon } from '../../Icons/Tick';
import { IDropdownValue } from '../Dropdown';

interface IAutocompleteOptionsProps {
  showOptions: boolean;
  disabled?: boolean;
  refreshOptions?: (searchValue: string) => Promise<void>;
  options: IDropdownValue[];
  internalOptions: IDropdownValue[];
  selectedOption: IDropdownValue;
  top?: boolean;
  query: string;
}

export const AutocompleteOptions = ({ showOptions, disabled, refreshOptions, options, internalOptions, selectedOption, top, query }: IAutocompleteOptionsProps) => {
  return <Transition
    show={showOptions && !disabled}
    as={Fragment}
    leave="transition ease-in duration-100"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <Combobox.Options
      static
      className={`absolute z-20 w-full py-1 overflow-auto ${top && 'mb-1 bottom-full'
        } text-base bg-black text-white rounded-b-md shadow-lg max-h-60 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
    >
      <Combobox.Option key={selectedOption?.value} value={selectedOption?.value} className={'cursor-pointer select-none relative py-4 pr-3 pl-9 text-white'}>
        {() => (
          <>
            <span
              className={'absolute inset-y-0 left-0 flex items-center pl-3 text-white'}
            >
              <div className='text-glacier'><TickIcon /></div>
            </span>
            <span className={`block truncate font-semibold`}>
              {selectedOption?.label}
            </span>
          </>
        )}
      </Combobox.Option>
      {(!!refreshOptions || internalOptions.length === 0 ? (query.length <= 0 ? options : []) : internalOptions)
        .filter((option) =>
          Array.isArray(selectedOption)
            ? !selectedOption.some((selectedOption) => selectedOption?.value === option?.value)
            : option?.value !== selectedOption?.value
        )
        .map((option) => (
          <Combobox.Option
            key={option.value}
            className={({ active }) => `cursor-pointer select-none relative py-4 pr-3 pl-9 ${active ? 'text-white' : 'text-white'
              }
                    `}
            value={option}
          >
            {({ selected, active }) => (
              <>
                {selected ? (
                  <span
                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-white'
                      }`}
                  >
                    <div className='text-glacier'><TickIcon /></div>
                  </span>
                ) : null}
                <span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'}`}>
                  {option.label}
                </span>
              </>
            )}
          </Combobox.Option>
        ))}
    </Combobox.Options>
  </Transition>;
};