import { User } from '@auth0/auth0-react';
import { Auth0OrganizationName, Auth0Role, Auth0UserDetails, IClientEntity } from '@shared/models';
import { isAdmin } from '@shared/services';
import { FastField, Formik } from 'formik';
import { getUserRole } from '../../providers/auth0.provider';
import { getOrganizationLabel } from '../../providers/sub-domain.service';
import { convertEnumToReadableString } from '../../providers/utility.provider';
import { IDropdownValue } from '../shared/Form/Dropdown';
import OldClientDropdown from '../shared/Form/Dropdowns/OldClientDropdown';
import OrganizationDropdown from '../shared/Form/Dropdowns/OrganizationDropdown';
import RoleDropdown from '../shared/Form/Dropdowns/RoleDropdown';
import { TextInput } from '../shared/Form/TextInput';
import { IUserForm, userFormScema } from './types';

interface IUserFormProps {
  user: Auth0UserDetails | undefined;
  auth0User: User | undefined;
  assignedClients: Partial<IClientEntity>[] | undefined;
  onSubmit: (formData: IUserForm) => Promise<void>;
}

const UserForm = ({ user, auth0User, assignedClients, onSubmit }: IUserFormProps): JSX.Element => {
  const organization = getOrganization(user, auth0User);
  const organizationLabel = getOrganizationLabel(organization);
  const role = getUserRole(user);

  const initialFormState: IUserForm = {
    email: user?.email?.trim() ?? '',
    firstName: user?.given_name?.trim() ?? '',
    lastName: user?.family_name?.trim() ?? '',
    blocked: user?.blocked ?? false,
    organization: {
      label: organizationLabel,
      value: organization,
    },
    role: {
      label: convertEnumToReadableString(role ?? ''),
      value: role,
    } ?? { label: '', value: '' },
    clients: assignedClients?.map((c) => ({ label: c.name, value: c.id } as IDropdownValue)) ?? [],
  };

  const isAdminRole = (values: IUserForm) => {
    return isAdmin(values?.role?.value as Auth0Role);
  };

  return (
    <>
      <Formik
        initialValues={initialFormState}
        onSubmit={(values) => {
          !!onSubmit && onSubmit(values);
        }}
        validationSchema={userFormScema}
      >
        {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
          <form id="user-form" onSubmit={handleSubmit}>
            <div className="flex flex-col px-1 pb-4 space-y-8 divide-y dark:divide-slate-800">
              <div className="space-y-4">
                <OrganizationDropdown
                  value={values.organization}
                  onChange={(newValue) => {
                    setFieldValue('organization', newValue);
                    setFieldTouched('organization');
                  }}
                  onBlur={() => {
                    setFieldTouched('organization');
                  }}
                  showError={!!(touched.organization && errors.organization)}
                  errorMessage={(errors?.organization as any)?.value as string}
                  disabled
                />
                <FastField
                  component={TextInput}
                  id="email"
                  name="email"
                  label="Email"
                  // disabled={isReadOnly}
                  value={values.email}
                  error={touched.email ? errors.email : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div className="flex space-x-4">
                  <FastField
                    component={TextInput}
                    className="flex-auto"
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    // disabled={isReadOnly}
                    value={values.firstName}
                    error={touched.firstName ? errors.firstName : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FastField
                    component={TextInput}
                    className="flex-auto"
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    // disabled={isReadOnly}
                    value={values.lastName}
                    error={touched.lastName ? errors.lastName : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <RoleDropdown
                  value={values.role}
                  onChange={(newValue) => {
                    setFieldValue('role', newValue);
                    setFieldTouched('role');
                  }}
                  onBlur={() => {
                    setFieldTouched('role');
                  }}
                  showError={!!(touched.role && errors.role && !values.role)}
                  errorMessage={(errors?.role as any)?.value as string}
                />
                {!isAdminRole(values) && <OldClientDropdown
                  label="Clients"
                  value={isAdminRole(values) ? [] : values?.clients ?? []}
                  onChange={(newValue) => {
                    setFieldValue('clients', newValue);
                  }}
                  onBlur={() => {
                    setFieldTouched('clients');
                  }}
                  placeholder={values?.clients?.length > 0 ? '' : 'Search for a Client'}
                  multiple={true}
                  showError={!!(touched.clients && errors.clients && !isAdminRole(values))}
                  errorMessage={errors.clients as string}
                  disabled={isAdminRole(values)}
                />}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

function getOrganization(user?: Auth0UserDetails, auth0User?: User) {
  const organization: Auth0OrganizationName = auth0User?.organization_name;
  return user ? getUserOrganization(user, organization) : organization;
}

function getUserOrganization(user: Auth0UserDetails, organization: Auth0OrganizationName) {
  const memberships = user?.app_metadata?.memberships ?? [];

  const match = memberships.find((m) => m?.organization === organization)?.organization;
  const first = memberships?.[0]?.organization;

  return match ?? first;
}

export default UserForm;
