import { Auth0Role } from '@shared/models';
import { chunk, isEmpty } from 'lodash';
import util from 'util';
import { v4, validate } from 'uuid';
import { AnySchema } from 'yup';
import { checkAuth0Roles } from '../auth/auth-roles.service';

export function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const uuid = v4;
export const isUUID = validate;

export function parseUUID(id: string) {
  return isUUID(id) ? id : undefined;
}

export function generateGUID(): string {
  return uuid();
}

export function stringToBase64(str: string): string {
  return Buffer.from(str, 'utf-8').toString('base64');
}

export function isDummyNumber(phoneNumber: number | string) {
  return extractPhoneNumberString(phoneNumber)?.substring(3, 6) === '555';
}

export function extractPhoneNumberString(value: number | string) {
  const phone = extractPhoneNumber(value);
  return phone ? String(phone) : null;
}

export function extractPhoneNumbers(values: (number | string)[]) {
  return values
    ?.map(value => extractPhoneNumber(value))
    ?.filter(value => !isNil(value))
    ?? [];
}

export function extractPhoneNumberStrings(values: (number | string)[]) {
  return values
    ?.map(value => extractPhoneNumberString(value))
    ?.filter(value => !isNil(value))
    ?? [];
}

export function extractPhoneNumber(value: number | string) {
  try {
    if (isNil(value)) {
      console.log(`bad number: '${value}'`);
      return null;
    }

    const str = typeof value === 'string' ? value : String(value);

    let result = str.replace(/\D/g, '').trim();

    // short code numbers
    if (result?.length === 5) {
      return parseInt(result);
    }

    if (result?.length < 10) {
      console.log(`bad number: '${value}'`);
      return null;
    }

    if (result?.charAt(0) === '1') {
      result = result?.substring(1);
    }

    if (result?.length !== 10) {
      console.log(`bad number: '${value}'`);
      return null;
    }

    if (result?.charAt(0) === '0') {
      console.log(`bad number: '${value}'`);
      return null;
    }

    return parseInt(result);
  } catch (error) {
    console.log(`bad number: '${value}'`);
    return null;
  }
}

export function formatPhoneE164(phoneNumber: string): string {
  let formatted = phoneNumber.replace(/\D/g, '');
  formatted = formatted.trim();

  if (formatted.length === 10) {
    return `+1${formatted}`;
  }

  return phoneNumber;
}

export function isNullOrEmptyOrUndefined(value: any): boolean {
  if (value === null || typeof value === 'undefined' || value === '' || value === 'undefined') {
    return true;
  }
  return false;
}

export function isNil(value: any): boolean {
  return [null, undefined, NaN].includes(value);
}

export function isString(value: any) {
  return !isNil(value) && typeof value == 'string';
}

export function isObject(value: any) {
  return !isNil(value) && typeof value == 'object';
}

export function isFunction(value: any) {
  return !isNil(value) && typeof value == 'function';
}

export function isArray(value: any) {
  return !isNil(value) && hasLength(value.length) && !isFunction(value);
}

export function range(start: number, size: number): ReadonlyArray<number> {
  return [...Array(size).keys()].map(i => i + start);
}

function hasLength(value: any) {
  return typeof value == 'number' && value > -1 && value % 1 == 0;
}

export function getSqlList(values: any[]) {
  return values.map(v => `'${v}'`).join(',');
}

export function deleteNilValues<T>(object: T): Partial<T> {
  const copy = clone(object);
  Object.keys(copy).forEach(key => isNil(copy[key]) ? delete copy[key] : {});
  return copy;
}

export function clone<T>(object: T): T {
  return JSON.parse(JSON.stringify(object));
}

export function clamp(value: number, min: number, max: number) {
  return value <= min
    ? min
    : value >= max
      ? max
      : value;
}

export function getFulfilledPromises<T>(results: PromiseSettledResult<T>[]) {
  return results.filter(p => p?.status === 'fulfilled').map(p => <PromiseFulfilledResult<T>>p).map(p => p?.value);
}

export function getRejectedPromises<T>(results: PromiseSettledResult<T>[]) {
  return results.filter(p => p?.status === 'rejected').map(p => <PromiseRejectedResult>p);
}

export function escapeSingleQuotes(value: string) {
  return value ? value.replace(new RegExp(`'`, 'g'), `''`) : '';
}

export function escapePercent(value: string) {
  return value ? value.replace(new RegExp('%', 'g'), '\\%') : '';
}

export function escapeDBString(value: string) {
  return value ? escapePercent(escapeSingleQuotes(value)) : '';
}

export function logDeep(...items: any[]) {
  console.log(util.inspect(items, { depth: 10 }));
}

export function capitalize(value: string) {
  return value.split(' ').map(v => v.charAt(0).toUpperCase() + v.slice(1)).join(' ');
};

export function chunkArray<T>(items: T[], size: number): T[][] {
  return chunk(items, size);
}

export interface BatchProcessRequest<T> {
  name: string;
  items: any[];
  size: number;
  parallel?: number;
  process: (chunk: any[]) => Promise<T>;
}

export async function batchProcess<T>({ name, items, size, process, parallel = 1 }: BatchProcessRequest<T>) {
  const time = name + ' ' + Date.now();
  try {
    if (!items || items.length <= 0) {
      return null;
    }

    console.time(time);

    const chunks = chunkArray(items, size);
    console.log(`${name}: Chunk Size: ${size}`);
    console.log(`${name}: Number of Chunks: ${chunks.length}`);

    const responses: T[] = [];

    for (let i = 0; i < chunks.length; i += parallel) {
      const indices = range(i, parallel);
      console.log(`${name}: Processing Chunk #${indices}`);
      const promises = indices.filter(j => j < chunks.length).map(j => process(chunks[j]));
      responses.push(...await Promise.all(promises));
    }

    return responses;
  } catch (error) {
    console.timeEnd(time);
    console.error(`${name}:`, { error });
    throw error;
  }
}

export function getUniqueItemsByProp<T>(items: T[], prop: keyof T) {
  const values = items.map(item => item[prop]);
  return items.filter((item, index) => !values.includes(item[prop], index + 1));
}

export function getPopulatedString(value?: string) {
  const trimmed = value?.trim() ?? undefined;
  return !isEmpty(trimmed) ? trimmed : undefined;
}

export function hasPopulatedString(value?: string) {
  return !isNil(getPopulatedString(value));
}

export function isAdminOrHigher(roles: Auth0Role[]) {
  return checkAuth0Roles({ userRoles: roles, targetRoles: [Auth0Role.A2P_ADMIN] });
}

export function validateObject<T>(request: T, schema: AnySchema): T {
  return schema.validateSync(schema.cast(request, {
    assert: true,
  }), {
    strict: true,
    abortEarly: true,
  });
}

export function isGUID(value: string): boolean {
  // Regular expression to check if string is a valid UUID
  const regex: RegExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regex.test(value);
}

export function extendObject<T>(value: T, data: Partial<T>): T {
  return <T>Object.assign({}, value ?? {}, data ?? {});
}

export function chunkString(value: string, length: number) {
  const items = value?.match(new RegExp('(.|\n|\r|\t|\f){1,' + length + '}', 'gm')) ?? [];
  return items?.filter(item => !!item)?.map(item => item) ?? [];
}

export function getFileMimeType(fileName: string): string {

  const extensionToMimeType: { [key: string]: string; } = {
    'jpg': 'image/jpeg',
    'jpeg': 'image/jpeg',
    'png': 'image/png',
    'gif': 'image/gif',
    'bmp': 'image/bmp',
    'svg': 'image/svg+xml',
    'txt': 'text/plain',
    'pdf': 'application/pdf',
    'doc': 'application/msword',
    'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'xls': 'application/vnd.ms-excel',
    'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'ppt': 'application/vnd.ms-powerpoint',
    'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'mp3': 'audio/mpeg',
    'wav': 'audio/wav',
    'mpeg': 'video/mpeg',
    'mp4': 'video/mp4',
    'mov': 'video/quicktime',
    'avi': 'video/x-msvideo',
    'ogv': 'video/ogg',
    'webm': 'video/webm',
    'html': 'text/html',
    'css': 'text/css',
    'js': 'application/javascript',
    'ts': 'application/typescript',
    'json': 'application/json',
    'xml': 'application/xml'
  };

  const extension = fileName.split('.').pop()?.toLowerCase() || '';

  return extensionToMimeType[extension] || 'application/octet-stream';

}

export function caseInsensitiveIncludes(value: string, targets: string[]) {
  return !!value
    && targets?.length > 0
    && !!targets?.find(target => value?.toLowerCase()?.trim()?.includes(target?.toLowerCase()?.trim()));
}

export type OptionalPromise<T> = Promise<T> | T;

const escapeAndReplaceHash = {
  "`": "'"
};

export function escapeAndReplaceDefinedCharacters(message: string) {
  const charactersToReplace = Object.keys(escapeAndReplaceHash);
  charactersToReplace.forEach((char) => {
    if (message.includes(char)) {
      message = message.replace(new RegExp(char, 'g'), escapeAndReplaceHash[char]);
    }
  });

  return message;
}

export const checkSHAFTCompliance = (message: string | undefined): boolean => {
  // !!WARNING!! BANNED WORDS //
  const bannedWords = [
    'Ass',
    'Asshole',
    'Chink',
    'Islamophobe',
    'homophobe',
    'Bastard',
    'Bitch',
    'Fucker',
    'Cunt',
    'Fuck',
    'Goddamn',
    'Shit',
    'Motherfucker',
    'Nigga',
    'Nigger',
    'Prick',
    'Shit',
    'son of a bitch',
    'Whore',
    'Thot',
    'Slut',
    'Faggot',
    'Dick',
    'Pussy',
    'Penis',
    'Vagina',
    'Negro',
    'Coon',
    'Bitched',
    'Cock',
    'Rape',
    'Molest',
    'Anal',
    'Buttrape',
    'Coont',
    'Sex',
    'Retard',
    'Fuckface',
    'Dumbass',
    'anal',
    'anus',
    'asshole',
    'assholes',
    'assmunch',
    'asswhole',
    'autoerotic',
    'ballsack',
    'bastard',
    'beastial',
    'beastiality',
    'bitch',
    'bitches',
    'bitchin',
    'bitching',
    'blow job',
    'blowjob',
    'blowjobs',
    'boner',
    'boob',
    'boobs',
    'breasts',
    'chink',
    'cock',
    'coon',
    'cum',
    'cunt',
    'dick',
    'dildo',
    'dildos',
    'ejaculate',
    'fag',
    'faggot',
    'fagot',
    'fucked',
    'fucker',
    'fuckers',
    'fuckhead',
    'fuckheads',
    'fuckin',
    'fucking',
    'god damn',
    'jackoff',
    'masterbate',
    'mothafucker',
    'nigg3r',
    'nigg4h',
    'nigga',
    'niggah',
    'niggas',
    'niggaz',
    'nigger',
    'niggers',
    'nob',
    'orgasim',
    'penis',
    'porn',
    'porno',
    'pornography',
    'prick',
    'pussy',
    'queer',
    'rectum',
    'retard',
    'semen',
    'sex',
    'shit',
    'slut',
    'testical',
    'tits',
    'vagina',
    'viagra',
    'dumbass',
    'Child predator',
  ];
  // END BANNED WORDS //
  const wordSearch = !bannedWords.some(word => getShaftWords(message ?? '').includes(word.toLowerCase()));
  const messageSearch = !bannedWords.some(word => getShaftMessage(message ?? '').includes(' ' + word.toLowerCase() + ' '));
  return wordSearch && messageSearch;
};

function getShaftWords(text: string) {
  return text
    ?.trim()
    ?.toLowerCase()
    ?.replace(/\s+/gm, ' ') // Squash sequential whitespace into one space
    ?.replace(/[^a-zA-Z0-9 ]/gm, ' ') // Remove all non alphanumeric characters
    ?.split(' ')
    ?.map(word => word?.trim()?.toLowerCase())
    ?.filter(word => !!word && word?.length > 0)
    ?? [];
}

function getShaftMessage(text: string) {
  return (' ' + text
    ?.trim()
    ?.toLowerCase()
    ?.replace(/\s+/gm, ' ') // Squash sequential whitespace into one space
    ?.replace(/[^a-zA-Z0-9 ]/gm, ' ') // Remove all non alphanumeric characters
    + ' ');
}

export function stringify(value: any) {
  switch (typeof value) {
    case 'string':
    case 'number':
    case 'bigint':
    case 'boolean':
    case 'symbol':
      return String(value);

    case 'object':
      return JSON.stringify(value, Object.getOwnPropertyNames(value), 2);

    default:
    case 'undefined':
    case 'function':
      return '';
  }
}

export function getOxfordCommaString(values: string[]) {
  const count = values?.length ?? 0;

  if (count <= 1) {
    return values;
  }

  if (count === 2) {
    return values.join(' and ');
  }

  const last = values.at(-1);
  const rest = values.slice(0, -1);

  return `${rest.join(', ')}, and ${last}`;
}