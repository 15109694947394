import { NotificationType, useNotifications } from 'apps/a2p-portal/src/contexts/NotificationContext';
import { FocusEventHandler, useEffect, useRef, useState } from 'react';
import { ErrorMessage } from './ErrorMessage';

interface IFileUpload {
  id: string;
  name: string;
  fileName?: string;
  value: File | undefined;
  accepts?: string;
  className?: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  error?: string;
  optional?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}
//const fileInputRef = useRef(null);

const NewFileUpload = ({
  id,
  name,
  fileName,
  value,
  accepts,
  className,
  label,
  disabled,
  placeholder,
  error,
  optional,
  onChange,
  onBlur,
}: IFileUpload): JSX.Element => {
  const { addNotification } = useNotifications();
  const [previousError, setPreviousError] = useState(error);
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState(null);

  // Used to fix the animation by not removing the text as we're transitioning out
  useEffect(() => {
    if (error) {
      setPreviousError(error);
    }
  }, [error]);

  const inputText = fileName
    ? fileName
    : value
      ? value.name
      : disabled
        ? 'No File Selected'
        : placeholder || null;


  const handleDrop = (event) => {



    if (accepts?.includes(event.dataTransfer.files[0].type.split('/')[0]) || event.dataTransfer.files[0].type === event.dataTransfer.files[0].name.slice(-4).toLowerCase()) {
      if (!inputRef.current) return;
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(event.dataTransfer.files[0]);
      inputRef.current.files = dataTransfer.files;
      console.log(inputRef.current.files);
      inputRef.current.dispatchEvent(new Event("change", {
        bubbles: true
      }));
    } else {
      addNotification({ header: `File must be ${accepts} format`, type: NotificationType.FAILURE });
      return;
    }
  };


  const handleClick = () => {
    inputRef?.current?.click();
  };

  return (
    <div className={className}>
      {/* Label */}
      <div className="flex justify-between">
        <label className="block text-xs text-gray-500">{label}</label>
        {optional && (
          <span className="text-sm" id="optional">
            Optional
          </span>
        )}
      </div>
      <div className="flex-col block p-4 mt-5 mb-5 text-base text-left break-words rounded-lg overflow-wrap min-h-6 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        onDrop={(event) => {
          event.preventDefault();
          handleDrop(event);
        }}
        onDragOver={(event) => {
          event.preventDefault();
          console.log("drag");
        }}>

        <label className='flex-grow w-auto space-x-2 text-sm'>
          <div className='align-middle'>
            <img className="float-left ml-2" id="grassroots-logo" width="52" height="40" src="/Upload.png" alt="Upload"></img>
          </div>
          {value?.type === undefined && (
            <>
              <div className='pl-24 mt-2 font-extrabold text-black'>{placeholder ?? "Drop your CSV file here"}<br /></div> <div className='pl-24 mb-2 text-gray-500'> or  <button className='text-blue-500' type='button' onClick={handleClick}> browse file</button> from your computer </div></>
          )}
          {value?.type !== undefined && (
            <div className='pl-8 overflow-auto text-justify min-h-11'><b> <button type='button' className='text-blue-500 min-h-11' onClick={handleClick}> {inputText}</button> </b></div>
          )}
        </label>
      </div >
      <ErrorMessage show={!!error} message={previousError} />
      <input hidden
        accept={accepts ?? '.csv'}
        name={name}
        value=""
        ref={inputRef}
        id={id}
        type="file"
        placeholder={'Select file'}
        onChange={onChange}
        onClick={() => {
          console.log(inputRef.current!.files);
          // This allows for users to re-upload the same file (eg. after modifications) by clearing the value on every file upload
          inputRef.current!.value = '';
        }}
      />
    </div>

  );
};

export { NewFileUpload };

